import { useEffect, useState } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

import BrandIcon from "assets/images/logo-white.svg"
import { useStore } from "state/store"

let timeout
let scroll = 0

export default function Header() {
  const headerVisible = useStore(state => state.headerVisible)
  const setHeaderVisible = useStore(state => state.setHeaderVisible)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (timeout) {
        clearTimeout(timeout)
      }

      timeout = setTimeout(() => {
        if (scroll >= window.scrollY) {
          setHeaderVisible(true)
        } else {
          setHeaderVisible(false)
        }
        scroll = window.scrollY
      }, 10)
    })
  }, [])

  return (
    <Holder
      variants={{ show: { y: 0 }, hidden: { y: -200 } }}
      initial="show"
      exit="hidden"
      animate={headerVisible ? "show" : "hidden"}
      transition={{ duration: 0.2 }}
    >
      <BrandHead>
        <a href="https://c4ads.org" target="_blank">
          <Brand alt="logo" src={BrandIcon} />
        </a>
      </BrandHead>
    </Holder>
  )
}

const Holder = styled(motion.div)`
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-out;
`

const BrandHead = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  background-color: #132b44;
`

const Brand = styled.img`
  width: 7em;
`
