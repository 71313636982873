import { SpecyWeightLegendData } from "dataset/legend"
import React from "react"
import styled from "styled-components"
import { device } from "theme/device"
import { addCommaToNumber } from "utils"

export default function AverageTable({ className, data }) {
  return (
    <Container className={className}>
      <Header>
        <div style={{ flex: 1.5, maxWidth: "150px" }}>&nbsp;</div>
        <div style={{ flex: 1 }}>{data?.[0]?.land ? "Land" : ""}</div>
        <div style={{ flex: 1 }}>{data?.[0]?.air ? "Air" : ""}</div>
        <div style={{ flex: 1 }}>{data?.[0]?.sea ? "Sea" : ""}</div>
        <div style={{ flex: 1 }}>{data?.[0]?.mail ? "Mail" : ""}</div>
      </Header>
      <Content>
        {data.map((row, index) => (
          <Row key={`${index}key`}>
            <div
              style={{
                flex: 1.5,
                maxWidth: "150px",
                textTransform: "capitalize"
              }}
            >
              {SpecyWeightLegendData.find(d => d.name === row.species)?.label}
            </div>
            <div style={{ flex: 1 }}>
              {row.land ? addCommaToNumber(Number(row.land).toFixed(1)) : ""}
            </div>
            <div style={{ flex: 1 }}>
              {row.air ? addCommaToNumber(Number(row.air).toFixed(1)) : ""}
            </div>
            <div style={{ flex: 1 }}>
              {row.sea ? addCommaToNumber(Number(row.sea).toFixed(1)) : ""}
            </div>
            <div style={{ flex: 1 }}>
              {row.mail ? addCommaToNumber(Number(row.mail).toFixed(1)) : ""}
            </div>
          </Row>
        ))}
      </Content>
    </Container>
  )
}

const Container = styled.div`
  border-top: 1px solid #cde2ef;
  box-sizing: border-box;
`

const Header = styled.div`
  background: #f3f9fd;
  color: #144a68;
  font-weight: bold;
  font-size: 1.2em;
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div:first-child {
    width: 320px;
  }
  @media ${device.mobileL} {
    font-size: 0.9em;
    & > div:first-child {
      width: 100px;
    }
  }
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
`

const Row = styled.div`
  display: flex;
  margin: 10px 0;
  color: #122945;
  font-size: 1em;
  line-height: 150%;
  & > div:first-child {
    width: 320px;
  }
  @media ${device.mobileL} {
    font-size: 0.9em;
    & > div:first-child {
      width: 100px;
    }
  }
`
