import styled from "styled-components"

//Tooltips
export const ChartTooltipHolder = styled.div`
  background-color: #fff;
  box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.15);
  color: #122945;
  padding: 1em;
  h5 {
    font-weight: bold;
    font-size: 0.9rem;
  }
  h6 {
    font-weight: 100;
    font-size: 0.9rem;
  }
`
