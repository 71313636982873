import axios from "axios"
import {
  LocationMissedLegendData,
  LocationTrafficLegendData,
  TransportTypeLegendData
} from "dataset/legend"
import CountryNameMatches from "dataset/country_name_matches.json"
import { groupBy } from "lodash"
import { roundNum } from "utils"

//Custom axios api
const defaultOptions = {
  mode: "cors", // no-cors, *cors, same-origin
  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  credentials: "same-origin", // include, *same-origin, omit
  redirect: "follow", // manual, *follow, error
  referrerPolicy: "no-referrer" // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
}

const baseURL = "https://65z5epax4c.execute-api.us-east-1.amazonaws.com/prod"

const axiosApi = axios.create({
  baseURL: baseURL,
  ...defaultOptions
})

////////////////////////////////////////////////////////////////////////////////
//Home Page
export const getCountryMap = async params => {
  let result = { data: [] }
  try {
    result = await axiosApi.get("/", {
      params: {
        category: "country_map",
        ...params
      }
    })
  } catch (error) {}

  const final = []
  result.data.forEach(item => {
    const countryCode = CountryNameMatches.find(
      country => country.c4ads === item.country
    )?.code
    const countryName = CountryNameMatches.find(
      country => country.c4ads === item.country
    )?.amchart4

    final.push({
      id: countryCode,
      name: countryName,
      cads_name: item.country,
      value: 120 * Math.log10(Number(item.sum)),
      realvalue: Number(item.sum)
    })
  })

  return final
}

export const getInstanceCountCountryCount = async params => {
  let result = { data: [] }
  try {
    result = await axiosApi.get("/", {
      params: {
        category: "instancecount_countrycount",
        ...params
      }
    })
  } catch (error) {}

  return result.data
}

////////////////////////////////////////////////////////////////////////////////
//Over Time
export const getTraffickingOverTIme = async params => {
  let result = { data: [] }
  try {
    result = await axiosApi.get("/", {
      params: {
        category: "trafficking_over_time",
        ...params
      }
    })
  } catch (error) {}

  let final = result.data.map(item => {
    return {
      year: item.year,
      overall: Number(item.overall_count),
      Pangolin: Number(item.pangolin),
      Tiger: Number(item.tiger),
      Leopard: Number(item.leopard),
      Lion: Number(item.lion),
      Ivory: Number(item.ivory),
      "Rhino Horn": Number(item.rhino),
      "Ivory ": Math.round(Number(item.ivory_weight)),
      "Rhino Horn ": Math.round(Number(item.rhino_weight)),
      "Pangolin Scales": Math.round(Number(item.pangolin_weight))
    }
  })

  return final
}

export const getTraffickingOverTImeBubble = async params => {
  let result = { data: [] }
  try {
    result = await axiosApi.get("/", {
      params: {
        category: "trafficking_over_time_bubble",
        ...params
      }
    })
  } catch (error) {}

  let final = result.data.map(item => {
    return {
      year: item.year,
      ivory_count: Number(item.ivory_count),
      pangolin_count: Number(item.pangolin_count),
      rhino_count: Number(item.rhino_count),
      ivory_amount: Number(item.ivory),
      pangolin_amount: Number(item.pangolin),
      rhino_amount: Number(item.rhino),
      ivory_weight: Number(item.ivory_weight),
      rhino_weight: Number(item.rhino_weight),
      pangolin_weight: Number(item.pangolin_weight)
    }
  })

  return final
}

export const getTextOverTime = async params => {
  let result = { data: [] }
  try {
    result = await axiosApi.get("/", {
      params: {
        category: "text_over_time",
        ...params
      }
    })
  } catch (error) {}

  return result.data
}

/////////////////////////////////////////////////////////////////////////////////////////////
// Transport Type
export const getProportionTransportType = async params => {
  let result = { data: [] }
  try {
    result = await axiosApi.get("/", {
      params: {
        category: "proportion_transport_type",
        ...params
      }
    })
  } catch (error) {}

  const final = []
  TransportTypeLegendData.map(legend => {
    const r = {}
    const a = result.data.find(
      item => item.transport_type === legend.upperLabel
    )
    if (a) {
      r.weight = Math.round(Number(a.weight))
      r.count = Number(a.count)
      r.color = legend.color
      r.label = legend.label
      final.push(r)
    }
  })

  return final
}

export const getSeizureWeightTransportType = async params => {
  let result = { data: [] }
  try {
    result = await axiosApi.get("/", {
      params: {
        category: "seizure_weight_transport_type",
        ...params
      }
    })
  } catch (error) {}

  result = groupBy(result.data, "transport_type")

  const startYear = Number(params.date_from.split("/")[2])
  const endYear = Number(params.date_to.split("/")[2])

  Object.keys(result).forEach(key => {
    let r_item = result[key]
    let new_r_item = []

    for (let i = startYear; i <= endYear; i++) {
      const d = r_item.find(item => item.year === i)
      const newItem = {}
      newItem.year = i

      if (d?.pangolin) {
        newItem["Pangolin"] = d.pangolin
      } else {
        newItem["Pangolin"] = 0
      }

      if (d?.tiger) {
        newItem["Tiger"] = d.tiger
      } else {
        newItem["Tiger"] = 0
      }

      if (d?.leopard) {
        newItem["Leopard"] = d.leopard
      } else {
        newItem["Leopard"] = 0
      }

      if (d?.lion) {
        newItem["Lion"] = d.lion
      } else {
        newItem["Lion"] = 0
      }

      if (d?.ivory) {
        newItem["Ivory"] = d.ivory
      } else {
        newItem["Ivory"] = 0
      }

      if (d?.rhino) {
        newItem["Rhino Horn"] = d.rhino
      } else {
        newItem["Rhino Horn"] = 0
      }

      if (d?.rhino) {
        newItem["Rhino Horn"] = d.rhino
      } else {
        newItem["Rhino Horn"] = 0
      }

      if (d?.ivory_weight) {
        newItem["Ivory "] = Number(d.ivory_weight)?.toFixed(1)
      } else {
        newItem["Ivory "] = 0
      }

      if (d?.rhino_weight) {
        newItem["Rhino Horn "] = Number(d.rhino_weight)?.toFixed(1)
      } else {
        newItem["Rhino Horn "] = 0
      }

      if (d?.pangolin_weight) {
        newItem["Pangolin Scales"] = Number(d.pangolin_weight)?.toFixed(1)
      } else {
        newItem["Pangolin Scales"] = 0
      }

      new_r_item.push(newItem)
    }

    result[key] = new_r_item
  })

  return result
}

export const getTotalWeightTransportType = async params => {
  let result = { data: [] }
  try {
    result = await axiosApi.get("/", {
      params: {
        category: "total_weight_transport_type",
        ...params
      }
    })
  } catch (error) {}

  const final = []
  TransportTypeLegendData.map(legend => {
    const r = {}
    const a = result.data.find(item => item.category === legend.upperLabel)
    if (a) {
      r.data = [
        {
          x: Math.round(Number(a.weight)),
          y: Number(a.noofseizures),
          z: Math.round(Number(a.weight) / Number(a.count)),
          label: legend.label
        }
      ]
      r.color = legend.color
      r.label = legend.label
    }
    final.push(r)
  })

  return final
}

export const getAverageWeightTransportType = async params => {
  let result = { data: [] }
  try {
    result = await axiosApi.get("/", {
      params: {
        category: "average_weight_transport_type",
        ...params
      }
    })
  } catch (error) {}
  return result.data
}

export const getTextTransportType = async params => {
  let result = { data: [] }
  try {
    result = await axiosApi.get("/", {
      params: {
        category: "text_transport_type",
        ...params
      }
    })
  } catch (error) {}

  return result.data
}

/////////////////////////////////////////////////////////////////////////////////////////////
//Location
export const getTraffickingRegions = async params => {
  let result = { data: [] }
  try {
    result = await axiosApi.get("/", {
      params: {
        category: "trafficking_regions",
        ...params
      }
    })
  } catch (error) {}

  let final = []
  let regions = []

  result.data.forEach(item => {
    final.push({ source: item.from, target: item.to, value: item.count })
    if (!regions.includes(item.from)) {
      regions.push(item.from)
    }
    if (!regions.includes(item.to)) {
      regions.push(item.to)
    }
  })

  return { results: final, regions: regions }
}
export const getLocationTrafficInstances = async params => {
  let result = { data: [] }
  try {
    result = await axiosApi.get("/", {
      params: {
        category: "location_traffic_instances",
        ...params
      }
    })
  } catch (error) {}

  let final = {
    missedSeizeDataResult: [],
    trafficDataResult: []
  }

  //traffic
  result.data?.trafficDataResult?.map(item => {
    item["Origin"] = roundNum(Number(item.originPercent || 0))
    item["Transit"] = roundNum(Number(item.transitPercent || 0))
    item["Destination"] = roundNum(Number(item.destinationPercent || 0))
  })
  result.data?.trafficDataResult?.sort((a, b) => b.total - a.total)

  for (let i = 0; i < result.data?.trafficDataResult?.length; i++) {
    if (i >= 20) {
      break
    } else {
      final.trafficDataResult.push(result.data?.trafficDataResult[i])
    }
  }

  //missed seize
  result.data?.missedSeizeDataResult?.map(item => {
    item["Missed"] = roundNum(Number(item.missedPercent || 0))
    item["Seized"] = roundNum(Number(item.seizurePercent || 0))
    item["No Opportunity"] = roundNum(Number(item.noopportunityPercent || 0))
  })

  final.trafficDataResult.forEach(item => {
    const d = result.data?.missedSeizeDataResult?.find(
      a => a.country === item.country
    )
    if (d) {
      final.missedSeizeDataResult.push(d)
    }
  })

  final.missedSeizeDataResult.forEach(item => {
    item.country = item.country.replace(
      "Democratic Republic of the Congo",
      "Congo"
    )
  })
  final.trafficDataResult.forEach(item => {
    item.country = item.country.replace(
      "Democratic Republic of the Congo",
      "Congo"
    )
  })

  return final
}
export const getLocationReactText = async params => {
  let result = { data: [] }
  try {
    result = await axiosApi.get("/", {
      params: {
        category: "location_reactive_sentence",
        ...params
      }
    })
  } catch (error) {}

  return result.data
}

/////////////////////////////////////////////////////////////////////////////////////////////
// Country Profile
export const getTraffickingMethodProfile = async params => {
  let result = { data: [] }
  try {
    result = await axiosApi.get("/", {
      params: {
        category: "proportion_transport_type",
        ...params
      }
    })
  } catch (error) {}

  result.data.forEach(item => {
    item.color = TransportTypeLegendData.find(
      ({ upperLabel }) => upperLabel === item.transport_type
    )?.color
  })

  return result.data
}

export const getLocationTopSeizure = async params => {
  let result = { data: [] }
  try {
    result = await axiosApi.get("/", {
      params: {
        category: "location_top_seizure",
        ...params
      }
    })
  } catch (error) {}

  return result.data
}

/////////////////////////////////////////////////////////////////////////////////////////////
// About page
export const getRegionDataAvailability = async params => {
  let result = { data: [] }
  try {
    result = await axiosApi.get("/", {
      params: {
        category: "region_data_availability",
        ...params
      }
    })
  } catch (error) {}

  result.data.map(item => {
    item.percentage = Number(Number(item.percentage).toFixed(1))
    item.name = item.name === "Transit1 Region" ? "Transit Region" : item.name
  })

  return result.data
}
