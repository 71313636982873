import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart
} from "recharts"

import {
  LocationMissedLegendData,
  LocationTrafficLegendData
} from "dataset/legend"
import { ChartTooltipHolder } from "./ChartElements"

export function LocationAStackChart({ data = [] }) {
  return (
    <ResponsiveContainer width="100%" height={800}>
      <ComposedChart
        layout="vertical"
        width={500}
        height={800}
        data={data}
        margin={{
          top: 20,
          right: 40,
          bottom: 20,
          left: 80
        }}
      >
        <CartesianGrid stroke="#C4C4C4" strokeDasharray="3 3" />
        <XAxis
          type="number"
          allowDataOverflow={true}
          domain={[0, 100]}
          tickCount={5}
          label={{
            value: "Percent(%)",
            position: "insideBottom",
            offset: -15,
            fontSize: "0.9em",
            fill: "#939393"
          }}
        />
        <YAxis dataKey="country" type="category" scale="band" fontSize="1em" />
        <Tooltip
          cursor={{ strokeDasharray: "3 3" }}
          content={<CustomTooltipA />}
        />
        {LocationTrafficLegendData.map((legend, index) => (
          <Bar
            key={index}
            dataKey={legend.label}
            stackId="a"
            fill={legend.color}
          />
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export function LocationBStackChart({ data }) {
  return (
    <ResponsiveContainer width="100%" height={800}>
      <ComposedChart
        layout="vertical"
        width={500}
        height={800}
        data={data}
        margin={{
          top: 20,
          right: 40,
          bottom: 20,
          left: 80
        }}
      >
        <CartesianGrid stroke="#C4C4C4" />
        <XAxis
          type="number"
          allowDataOverflow={true}
          domain={[0, 100]}
          tickCount={5}
          label={{
            value: "Percent(%)",
            position: "insideBottom",
            offset: -15,
            fontSize: "0.9em",
            fill: "#939393"
          }}
        />
        <YAxis dataKey="country" type="category" scale="band" />
        <Tooltip
          cursor={{ strokeDasharray: "3 3" }}
          content={<CustomTooltipB />}
        />
        {LocationMissedLegendData.map((legend, index) => (
          <Bar
            key={index}
            dataKey={legend.label}
            stackId="a"
            fill={legend.color}
          />
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  )
}

const CustomTooltipA = ({ active, payload }) => {
  if (!active) return null

  for (let i = 0; i < payload.length; i++) {
    const data = payload[i].payload
    return (
      <ChartTooltipHolder>
        <h5>{data.country}</h5>
        {LocationTrafficLegendData.map((legend, index) => (
          <h6 key={index}>{`${legend.label} : ${data[legend.label]}%`}</h6>
        ))}
      </ChartTooltipHolder>
    )
  }

  return null
}

const CustomTooltipB = ({ active, payload }) => {
  if (!active) return null

  for (let i = 0; i < payload.length; i++) {
    const data = payload[i].payload
    return (
      <ChartTooltipHolder>
        <h5>{data.country}</h5>
        {LocationMissedLegendData.map((legend, index) => (
          <h6 key={index}>{`${legend.label} : ${data[legend.label]}%`}</h6>
        ))}
      </ChartTooltipHolder>
    )
  }

  return null
}
