import { SpecyLegendData } from "dataset/legend"
import styled from "styled-components"

import InfoIcon from "assets/images/icon-info.svg"

export default function SpecyHeader() {
  return (
    <Holder>
      <TitleHolder>
        <h4>Seizures Over Time</h4>
      </TitleHolder>
      <LegendHolder>
        {SpecyLegendData.map((item, index) => (
          <LegendItem key={index}>
            <LegendIcon color={item.color} />
            <LegendLabel>{item.label}</LegendLabel>
          </LegendItem>
        ))}
      </LegendHolder>
      <a data-tip data-for="OverTime-Tooltip">
        <InfoImg src={InfoIcon} />
      </a>
    </Holder>
  )
}

const Holder = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  background-color: #f3f9fd;
  padding: 0.5em;
  justify-content: space-between;
`

const TitleHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  & > h4 {
    font-size: 1.1em;
    color: #144a68;
    font-weight: bold;
    margin: 0;
    padding: 0;
  }
  & > h5 {
    font-size: 0.85em;
    color: #144a68;
    margin: 0;
    padding: 0;
    letter-spacing: 0.02em;
  }
`

const LegendHolder = styled.div`
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
`

const LegendItem = styled.div`
  display: flex;
  align-items: center;
`
const LegendIcon = styled.div`
  width: 0.5em;
  height: 0.5em;
  border-radius: 0.4em;
  background-color: ${props => props.color};
  margin-right: 0.5em;
`
const LegendLabel = styled.div`
  font-size: 0.9em;
  text-transform: capitalize;
  color: #122945;
`
const InfoImg = styled.img`
  width: 1.5em;
  height: 1.5em;
`
