import { useState, useEffect } from "react"
import * as d3 from "d3"
import { SideContainer } from "components/Containers"
import { useStore } from "state/store"
import { useQuery } from "react-query"
import { useMatomo } from "@datapunt/matomo-tracker-react"
import {
  getLocationReactText,
  getLocationTrafficInstances,
  getTraffickingRegions
} from "api"
import Loader from "components/Loader"
import styled from "styled-components"
import {
  convert2Region,
  convert2Method,
  convert2Specie
} from "utils/convertDataFormat"
import { countries } from "dataset/filters"
import { device } from "theme/device"
import Card, { Legend } from "components/Card"
import {
  LocationAStackChart,
  LocationBStackChart
} from "components/Charts/LocationStackChart"
import {
  ChartDesc,
  SLDesc,
  SLReactText,
  SLTitle
} from "components/SeveralLetters"
import {
  ChordColors,
  LocationMissedLegendData,
  LocationTrafficLegendData
} from "dataset/legend"
import { replaceAndText } from "utils"

export default function TraffickingRegion() {
  const { trackPageView } = useMatomo()
  const setSideBarVisible = useStore(state => state.setSideBarVisible)

  const selectedSpecie = useStore(state => state.selectedSpecie)
  const selectedMethod = useStore(state => state.selectedMethod)
  const selectedRegion = useStore(state => state.selectedRegion)
  const selectedDuringFrom = useStore(state => state.selectedDuringFrom)
  const selectedDuringTo = useStore(state => state.selectedDuringTo)

  const specieData = convert2Specie(selectedSpecie)
  const methodData = convert2Method(selectedMethod)
  const regionData = convert2Region(selectedRegion)
  const [reactSpecy, setReactSpecy] = useState("wildlife product")

  const {
    data: ChordChartData,
    error: ChordChartError,
    isError: ChordChartIsError,
    isLoading: ChordChartIsLoading
  } = useQuery(
    [
      "getTraffickingRegions",
      specieData,
      methodData,
      selectedDuringFrom,
      selectedDuringTo,
      regionData
    ],
    () =>
      getTraffickingRegions(
        {
          analyze: specieData,
          transport_type: methodData,
          date_from: selectedDuringFrom,
          date_to: selectedDuringTo,
          region: regionData
        },
        countries
      ),
    {
      keepPreviousData: true
    }
  )

  const {
    data: BarChartData,
    error: BarChartError,
    isError: BarChartIsError,
    isLoading: BarChartIsLoading
  } = useQuery(
    [
      "getLocationTrafficInstances",
      specieData,
      methodData,
      selectedDuringFrom,
      selectedDuringTo,
      regionData
    ],
    () =>
      getLocationTrafficInstances(
        {
          analyze: specieData,
          transport_type: methodData,
          date_from: selectedDuringFrom,
          date_to: selectedDuringTo,
          region: regionData
        },
        countries
      ),
    {
      keepPreviousData: true
    }
  )

  //React text
  const { data: reactTextData } = useQuery(
    [
      "getLocationReactText",
      specieData,
      methodData,
      selectedDuringFrom,
      selectedDuringTo,
      regionData
    ],
    () =>
      getLocationReactText({
        analyze: specieData,
        transport_type: methodData,
        year_from: selectedDuringFrom.split("/")[2],
        year_to: selectedDuringTo.split("/")[2],
        region: regionData
      }),
    {
      keepPreviousData: true
    }
  )

  useEffect(() => {
    let newVal = ""
    if (specieData === "") {
      newVal = "wildlife product"
    } else {
      newVal = replaceAndText(specieData)
    }
    setReactSpecy(newVal)
  }, [specieData])

  useEffect(() => {
    trackPageView({
      documentTitle: window.location.pathname + window.location.search
    })
    setSideBarVisible(true)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (ChordChartData) {
      drawChord()
    }
    // eslint-disable-next-line
  }, [ChordChartData])

  const drawChord = async () => {
    let width = 320
    let height = width
    let innerRadius = Math.min(width, height) * 0.5 - 20
    let outerRadius = innerRadius + 6

    let names = ChordChartData?.regions
    let data = ChordChartData?.results

    const index = new Map(names.map((name, i) => [name, i]))
    let matrix = Array.from(index, () => new Array(names.length).fill(0))
    for (const { source, target, value } of data)
      matrix[index.get(source)][index.get(target)] += value

    let chord = d3
      .chordDirected()
      .padAngle(12 / innerRadius)
      .sortSubgroups(d3.descending)
      .sortChords(d3.descending)
    let arc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius)
    let ribbon = d3
      .ribbonArrow()
      .radius(innerRadius - 0.5)
      .padAngle(1 / innerRadius)

    var tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "chord-tooltip")
      .style("opacity", 0)
    const svg = d3
      .select("#trafficking-region")
      .attr("viewBox", [-width / 2, -height / 2, width, height])

    const chords = chord(matrix)

    svg.selectAll("*").remove()

    svg
      .append("path")
      .attr("id", "text")
      .attr("fill", "none")
      .attr(
        "d",
        d3.arc()({
          outerRadius,
          startAngle: 0,
          endAngle: 2 * Math.PI
        })
      )

    svg
      .append("g")
      .attr("fill-opacity", 0.75)
      .selectAll("g")
      .data(chords)
      .join("path")
      .attr("d", ribbon)
      .attr("class", "chord")
      .attr("fill", d => ChordColors[d.source.index])
      .style("mix-blend-mode", "multiply")
      .on("mouseover", (e, d) => onMouseOver(e, d))
      .on("mouseout", onMouseOut)
      .append("title")

    function onMouseOver(e, selected) {
      svg
        .selectAll(".chord")
        .filter(
          d =>
            !(
              d.source.index === selected.source.index &&
              d.target.index === selected.target.index
            )
        )
        .style("opacity", 0.3)
      tooltip.transition().duration(200).style("opacity", 0.9)
      tooltip
        .html(
          `From: <strong>${
            names[selected.source.index]
          }</strong> <br/> To: <strong> ${
            names[selected.target.index]
          }</strong> <br/> Number: <strong> ${selected.source.value}</strong>`
        )
        .style("left", e.pageX - 90 + "px")
        .style("top", e.pageY + "px")
    }

    function onMouseOut() {
      svg.selectAll(".chord").style("opacity", 0.75)
      tooltip.transition().duration(500).style("opacity", 0)
    }

    svg
      .append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .selectAll("g")
      .data(chords.groups)
      .join("g")
      .call(g =>
        g
          .append("path")
          .attr("d", arc)
          .attr("fill", d => ChordColors[d.index])
          .attr("stroke", "#fff")
      )
  }

  return (
    <SideContainer>
      <SLTitle>Global Scale</SLTitle>
      <SLDesc>
        The chart below demonstrates the flow of the illicit wildlife trade
        between regions. As this chart indicates, the scope of wildlife
        trafficking is truly international. From origin points in countries that
        serve as sources of wildlife, through transit countries with
        infrastructure for the forward movement of wildlife shipments, to
        destination countries that function as end markets for wildlife
        specimens, a shipment of wildlife or wildlife product may traverse
        numerous jurisdictions during its journey.
      </SLDesc>
      <SLReactText>
        {regionData === ""
          ? `There are ${reactTextData?.countryRanks.length} jurisdictions associated with globally-linked seizures. Out of these jurisdictions, ${reactTextData?.topSeizuresCountryName} was associated with the highest number of ${reactSpecy} seizures between ${reactTextData?.fromYear} and ${reactTextData?.toYear}.`
          : `There are ${reactTextData?.countryRanks.length} jurisdictions associated with seizures linked to the filtered location(s). Out of these jurisdictions, ${reactTextData?.topSeizuresCountryName} was associated with the highest number of wildlife product seizures between ${reactTextData?.fromYear} and ${reactTextData?.toYear}.`}
      </SLReactText>
      <Card
        title="Wildlife Trafficking Between Regions"
        desc=""
        showSpecies={false}
        showDownload={false}
      >
        {ChordChartIsLoading ? (
          <Loader />
        ) : ChordChartIsError ? (
          ChordChartError
        ) : (
          <RegionContainer>
            <Chart id="trafficking-region" />
            <div className="my-auto ml-4 mr-auto">
              {ChordChartData?.regions.map((region, index) => (
                <ChordLegend
                  key={index}
                  label={region}
                  index={index}
                  names={ChordChartData?.regions}
                />
              ))}
            </div>
          </RegionContainer>
        )}
      </Card>
      <SLTitle className="mt-4">
        Number of Seizures vs. Number of Country-Linked Seizures
      </SLTitle>
      <SLDesc>
        A seizure count is the number of interdictions of wildlife or wildlife
        products in a given location, whereas a country-linked seizure count
        refers to the number of incidents of wildlife trafficking that
        originated, transited, or were destined for a specific country,
        regardless of whether or not the incident of wildlife trafficking was
        interdicted in that country. The county-linked seizure count of a
        country is therefore always equal to or greater than the seizure count
        of the same country.
      </SLDesc>
      <SLDesc>
        For example, if a shipment of rhino horn originating in South Africa and
        destined for Vietnam is seized in transit at an airport in Turkey,
        Turkey would have a count of 1 for seizures and 1 for country-linked
        seizures; South Africa would have a count of 0 for seizures and 1 for
        country-linked seizures, and Vietnam would have a count of 0 for
        seizures and 1 for country-linked seizure.
      </SLDesc>
      <SLDesc>
        Instances of a wildlife trafficking shipment passing through a country
        without being seized are considered “missed” seizure opportunities.
        Furthermore, in instances where a wildlife trafficking shipment is
        seized prior to reaching its intended destination, the destination
        country would be considered as having had “no opportunity” to seize the
        shipment. Therefore, in the prior example South Africa would be
        considered as having “missed” the seizure and Vietnam would be
        considered as having had “no opportunity” to interdict the shipment.
      </SLDesc>
      <CardGroup>
        <StyledCard1
          className="mt-4"
          title="Country Role in Wildlife Trafficking Supply Chain"
          desc=""
          showSpecies={false}
          showDownload
        >
          <ChartDesc>
            This graph shows where specific countries* are recorded to have
            fallen in the known trafficking route of seized wildlife shipments.
            This information can help stakeholders better understand the role of
            certain countries as sourcing, transit, and consumer locations,
            which in turn can help guide counter-wildlife trafficking efforts.
            The categorization of “origin,” “transit,” and “destination” are
            derived from how the trafficking routes are reported.<br/>
            <b>
              Since many seizures occur at transportation hubs, and the ultimate
              poaching location of the items is often not reported, the dataset
              represented in this graph tends to be biased towards classifying
              seizure locations, and thus countries, as “transit” locations even
              though it is possible that the shipment originated from within
              that same country as well.
            </b>
            <br/>
            <br/>
            *This graphic displays the 20 most frequently appearing countries in the supply chain.
          </ChartDesc>
          {BarChartIsLoading ? (
            <Loader />
          ) : BarChartIsError ? (
            BarChartError
          ) : (
            <>
              <div className="row p-3">
                <LegendHolder>
                  <LegendGroup>
                    {LocationTrafficLegendData.map((legend, index) => (
                      <Legend key={index} {...legend} />
                    ))}
                  </LegendGroup>
                </LegendHolder>
                <LocationAStackChart data={BarChartData.trafficDataResult} />
              </div>
              <CardDiv className="row p-3">
                <LegendHolder>
                  <LegendGroup>
                    {LocationMissedLegendData.map((legend, index) => (
                      <Legend key={index} {...legend} />
                    ))}
                  </LegendGroup>
                </LegendHolder>
                <LocationBStackChart
                  data={BarChartData.missedSeizeDataResult}
                />
              </CardDiv>
            </>
          )}
        </StyledCard1>
        <StyledCard2
          className="mt-4"
          title="Country Enforcement Success&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
          desc=""
          showSpecies={false}
          showDownload
        >
          <ChartDesc>
            This graph shows the relative success rate of specific countries in
            interdicting shipments of illicit wildlife that travel through their
            country. This information can be used to better understand how
            successful each country’s enforcement practices are in identifying
            and apprehending illicit wildlife product. Note that destination
            countries are more likely to have a higher proportion of seized
            shipments, since shipments that are not stopped at their destination
            cannot be seized at another airport along their route, and so will
            not be reported in the open source.
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </ChartDesc>
          {BarChartIsLoading ? (
            <Loader />
          ) : BarChartIsError ? (
            BarChartError
          ) : (
            <div className="row p-3">
              <LegendHolder>
                <LegendGroup>
                  {LocationMissedLegendData.map((legend, index) => (
                    <Legend key={index} {...legend} />
                  ))}
                </LegendGroup>
              </LegendHolder>
              <LocationBStackChart data={BarChartData.missedSeizeDataResult} />
            </div>
          )}
        </StyledCard2>
      </CardGroup>
    </SideContainer>
  )
}

const RegionContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`

const Chart = styled.svg`
  width: 600px;
  height: 600px;
  position: relative;
`

const LegendHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    margin: 0;
  }
`
const LegendGroup = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1em;
`

const ChordLegend = ({ label, index, names }) => {
  return (
    <LegendContainer>
      <LegendBullet color={ChordColors[index]} />
      <LegendText>{label}</LegendText>
    </LegendContainer>
  )
}

const LegendContainer = styled.div`
  display: flex;
`
const LegendBullet = styled.div`
  width: 14px;
  min-width: 14px;
  height: 14px;
  background: ${props => props.color};
  border-radius: 50%;
  margin: auto 8px auto 0;
`
const LegendText = styled.div`
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #122945;
`
const CardGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media ${device.pad} {
    flex-direction: column;
  }
`
const StyledCard1 = styled(Card)`
  width: 50%;
  @media ${device.pad} {
    width: 100%;
  }
`

const StyledCard2 = styled(Card)`
  width: 50%;
  @media ${device.pad} {
    display: none;
  }
`

const CardDiv = styled.div`
  display: none;
  @media ${device.pad} {
    display: block;
  }
`
