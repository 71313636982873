import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  LabelList
} from "recharts"

export default function AboutDataAvailabilityChart({ data = [] }) {
  return (
    <ResponsiveContainer width="100%" height={350}>
      <ComposedChart
        layout="vertical"
        width={500}
        height={350}
        data={data}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 80
        }}
      >
        <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" />
        <XAxis type="number" unit="%" />
        <YAxis
          dataKey="name"
          type="category"
          scale="band"
          tick={{ fontSize: "1em", color: "#122945" }}
          tickLine={false}
          tickMargin={5}
          width={80}
        />
        <Tooltip />
        <Bar dataKey="percentage" stackId="a" fill="#EF6D77" barSize={30}>
          <LabelList
            dataKey="percentage"
            position="insideRight"
            style={{ fill: "#fff" }}
            formatter={value => `${value}%`}
          />
        </Bar>
      </ComposedChart>
    </ResponsiveContainer>
  )
}
