export * from "./downloadImage"

//Relace "a,b,c" with "a,b-c"
export function replaceLast(x, y, z) {
  var a = x.split("")
  a[x.lastIndexOf(y)] = z
  return a.join("")
}

//Replace "a,b,c" to "a, b, and c"
export function replaceAndText(str) {
  let newVal = ""
  if (str.split(",").length === 2) {
    newVal = str.split(",").join(" and ")
  } else if (str.split(",").length > 2) {
    newVal = replaceLast(str, ",", ",and ")
    newVal = newVal.replaceAll(",", ", ")
  } else {
    newVal = str
  }
  return newVal
}

//add comma separator to number
export function addCommaToNumber(x) {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  return ""
}

export function getUrlParams() {
  var result = {}
  var params = (window.location.search.split("?")[1] || "").split("&")
  for (var param in params) {
    if (params.hasOwnProperty(param)) {
      var paramParts = params[param].split("=")
      result[paramParts[0]] = decodeURIComponent(paramParts[1] || "")
    }
  }
  return result
}

export function roundNum(num) {
  return Math.round((num + Number.EPSILON) * 10) / 10
}
