import html2canvas from "html2canvas"
import pdfMake from "pdfmake"

export async function downloadImage(ref) {
  if (ref) {
    const logoUrl =
      window.location.protocol + "//" + window.location.host + "/c4ads-logo.png"
    const fontUrl =
      window.location.protocol +
      "//" +
      window.location.host +
      "/fonts/century_gothic.ttf"
    // 1. get the chart legend from the HTML and render into an image
    //    this includes the chart title element
    let chartSvgs = [...ref.querySelectorAll("svg")].slice(1)
    let legend = ref.children[0].children[0]

    // if this is a graph where the legend is right above the chart instead of by the
    // download button, do some cursed dom traversal to get the legend and append it to the title
    if (legend.children.length == 2) {
      legend.append(
        chartSvgs[0].parentElement.parentElement.parentElement.children[0]
      )
    }

    await html2canvas(legend, {
      dpi: 300,
      logging: true
    }).then(canvas => {
      let ctx = canvas.getContext("2d")
      const legendImgUri = canvas.toDataURL("image/jpeg", 1.0)

      // 2. fix the chart svg due to rechart bugs:
      // a. by removing invisible dashes that will not meet PDF spec
      // b. by setting viewBox when it is not set (no viewbox prevents scaling svg)
      // the first SVG inside the ref element is the download button, so ignore it
      if (chartSvgs) {
        chartSvgs.forEach(svg => {
          if (svg.getAttribute("viewBox") == null) {
            svg.setAttribute("viewBox", "0 0 300 300")
          }
          ;[...svg.children].forEach(child => {
            ;[...child.querySelectorAll("path")].forEach(path => {
              path.removeAttribute("stroke-dasharray")
            })
          })
        })
      }

      // 3. construct and download the PDF, using pdfmake table for layout and adding logo
      if (chartSvgs && legendImgUri) {
        pdfMake.fonts = {
          centuryGothic: {
            normal: fontUrl,
            bold: fontUrl
          }
        }
        var pdfDesc = {
          content: [
            {
              image: legendImgUri,
              // hack to make the tall charts' weirdly long labels fit
              width: chartSvgs[0].height.baseVal.value > 700 ? 300 : 480,
              style: "legend",
              relativePosition: {
                // hack to make the tall charts' weirdly long labels fit
                x: chartSvgs[0].height.baseVal.value > 700 ? 0 : 125,
                y: 0
              },
              align: "right"
            },
            {
              image: "logo",
              width: 100,
              relativePosition: { x: 10, y: 0 }
              //margin: [30,30,0,0],
            }
          ],
          defaultStyle: {
            font: "centuryGothic"
          },
          styles: {
            legend: {
              alignment: "right"
            },
            chart: {
              alignment: "center"
            }
          },
          images: {
            logo: logoUrl
          }
        }
        // assemble the chart svgs into a pdfmake table
        var colW = chartSvgs.length == 1 ? 500 : 250
        var imgW = chartSvgs.length == 1 ? colW : colW - 50
        // force downscale for tall charts that would go off page
        var imgH = chartSvgs[0].height.baseVal.value <= 700 ? undefined : 600
        var tableContent = {
          layout: "noBorders",
          table: {
            headerRows: 0,
            body: [],
            widths: [colW, colW],
            margin: [100, 0, 0, 0]
          },
          relativePosition: { x: 0, y: 50 }
        }
        tableContent.table.body.push([])
        if (chartSvgs.length > 2) {
          tableContent.table.body.push([])
        }
        chartSvgs.forEach((svg, i) => {
          tableContent.table.body[Math.floor(i / 2.0)].push({
            svg: svg.outerHTML,
            width: imgW,
            height: imgH,
            alignment: "left"
          })
        })
        pdfDesc.content.push(tableContent)
        var doc = pdfMake.createPdf(pdfDesc).download("c4ads-data-export.pdf")
      }
    })
  }
}
