import { regions } from "dataset/filters"

export const convert2Region = (data = []) => {
  let countries = []
  Object.keys(data).forEach(item => {
    const region = regions.find(a => a.title === item)
    if (region) {
    } else {
      countries.push(item)
    }
  })

  let result = countries.join()
  return result
}

export const convert2Date = date => {
  const dd = String(date.getDate()).padStart(2, "0")
  const mm = String(date.getMonth() + 1).padStart(2, "0") //January is 0!
  const yyyy = date.getFullYear()

  return mm + "/" + dd + "/" + yyyy
}

export const convert2Specie = (data = []) => {
  let result = data.join()
  return result
}

export const convert2Method = (data = []) => {
  let result = data.join()
  return result
}
