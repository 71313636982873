import { SpecyLegendData } from "dataset/legend"
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts"

export default function SeizureChart({ data = [], width = 350 }) {
  return (
    <BarChart
      width={width}
      height={350}
      data={data}
      margin={{
        top: 20,
        bottom: 20,
        left: 20,
        right: 20
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="year"
        label={{
          value: "Years",
          position: "insideBottom",
          offset: -15,
          fontSize: "0.9em",
          fill: "#939393"
        }}
      />
      <YAxis
        label={{
          value: "Number of Seizures",
          angle: -90,
          position: "insideLeft",
          dy: 50,
          dx: -5,
          fontSize: "0.9em",
          fill: "#939393"
        }}
      />
      <Tooltip />
      {SpecyLegendData.map((item, index) => (
        <Bar key={index} dataKey={item.label} stackId="a" fill={item.color} />
      ))}
    </BarChart>
  )
}
