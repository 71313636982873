export const TransportTypeLegendData = [
  { label: "Air", name: "air", color: "#5074BA", upperLabel: "AIR" },
  { label: "Land", name: "land", color: "#479577", upperLabel: "LAND" },
  { label: "Mail", name: "mail", color: "#2AA1E3", upperLabel: "MAIL" },
  { label: "Sea", name: "sea", color: "#7CCFC0", upperLabel: "SEA" },
  { label: "Unknown", name: "unknown", color: "#92B2C5", upperLabel: "UNKNOWN" }
]

export const SpecyLegendData = [
  { label: "Ivory", name: "ivory", color: "#31617C" },
  { label: "Leopard", name: "leopard", color: "#D8C092" },
  { label: "Lion", name: "lion", color: "#122945" },
  { label: "Pangolin", name: "pangolin", color: "#F27676" },
  { label: "Rhino Horn", name: "rhino", color: "#73A98F" },
  { label: "Tiger", name: "tiger", color: "#848458" }
]

export const SpecyWeightLegendData = [
  { label: "Ivory ", name: "ivory", color: "#31617C" },
  { label: "Pangolin Scales", name: "pangolin", color: "#F27676" },
  { label: "Rhino Horn ", name: "rhino", color: "#73A98F" }
]

export const LocationMissedLegendData = [
  { label: "Missed", name: "missed", color: "#907F8A" },
  { label: "Seized", name: "seizure", color: "#5BB4B6" },
  { label: "No Opportunity", name: "noopportunity", color: "#B5BA67" }
  // { label: "Unknown", name: "unknown", color: "#C4C4C4" }
]

export const LocationTrafficLegendData = [
  { label: "Origin", name: "origin", color: "#F27676" },
  { label: "Transit", name: "transit", color: "#73A98F" },
  { label: "Destination", name: "destination", color: "#31617C" }
]

export const ChordColors = [
  "#EF6D77", // Asia
  "#84835C", // Africa
  "#D7BE96", // Europe
  "#75899F", // Americas
  "#B0A1F7" // Oceania
]
