import {
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ResponsiveContainer,
  LineChart
} from "recharts"
import { SpecyLegendData, SpecyWeightLegendData } from "dataset/legend"
import { ChartTooltipHolder } from "./ChartElements"

export default function OverTimeLineChart({ data = [], activeWeight = false }) {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        width={300}
        height={300}
        data={data}
        margin={{
          top: 20,
          bottom: 20,
          left: 20,
          right: 20
        }}
      >
        <Tooltip
          cursor={{ strokeDasharray: "3 3" }}
          content={<CustomTooltip activeWeight={activeWeight} />}
        />
        <XAxis
          dataKey="year"
          label={{
            value: "Years",
            position: "insideBottom",
            offset: -15,
            fontSize: "0.9em",
            fill: "#939393"
          }}
        />
        {activeWeight ? (
          <YAxis
            type="number"
            domain={[0, "auto"]} //domain={['auto', 'auto']} domain={[0, 'dataMax']}
            orientation="left"
            dx={-5}
            label={{
              value: "Weight of Seizures (kg)",
              angle: -90,
              position: "insideLeft",
              dy: 60,
              dx: -15,
              fontSize: "0.9em",
              fill: "#939393"
            }}
          />
        ) : (
          <YAxis
            type="number"
            domain={[0, "auto"]} //domain={['auto', 'auto']} domain={[0, 'dataMax']} domain={[0, 'dataMax + 1000']}
            orientation="left"
            dx={-5}
            allowDataOverflow={true}
            label={{
              value: "Number of Seizures",
              angle: -90,
              position: "insideLeft",
              dy: 50,
              dx: -5,
              fontSize: "0.9em",
              fill: "#939393"
            }}
          />
        )}

        {activeWeight
          ? SpecyWeightLegendData.map((item, index) => (
              <Line
                type="monotone"
                dataKey={item.label}
                fill={item.color}
                stroke={item.color}
                key={index}
              />
            ))
          : SpecyLegendData.map((item, index) => (
              <Line
                type="monotone"
                dataKey={item.label}
                fill={item.color}
                stroke={item.color}
                key={index}
              />
            ))}
      </LineChart>
    </ResponsiveContainer>
  )
}

const CustomTooltip = ({ active, payload, activeWeight }) => {
  if (!active) return null

  for (let i = 0; i < payload.length; i++) {
    const data = payload[i].payload
    return (
      <ChartTooltipHolder>
        <h5>{data.year}</h5>
        {data.overall !== undefined && !activeWeight && (
          <h6>{`Overall : ${data.overall}`}</h6>
        )}
        {activeWeight
          ? SpecyWeightLegendData.map(
              (item, index) =>
                data[item.label] !== undefined && (
                  <h6 key={index}>{`${item.label} : ${data[item.label]}`}</h6>
                )
            )
          : SpecyLegendData.map(
              (item, index) =>
                data[item.label] !== undefined && (
                  <h6 key={index}>{`${item.label} : ${data[item.label]}`}</h6>
                )
            )}
      </ChartTooltipHolder>
    )
  }

  return null
}
