import { useEffect } from "react"
import { forEach } from "lodash"
import styled from "styled-components"
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import am4themes_animated from "@amcharts/amcharts4/themes/animated"
import { removeAMChartMarker } from "utils/removeAMChartMarker"
import { device } from "theme/device"

am4core.useTheme(am4themes_animated)

export default function TransportTypeEmChart({ data = [] }) {
  useEffect(() => {
    // Create chart instance
    let chart = am4core.create("transport_type_em_chart", am4charts.PieChart)

    // Add data
    chart.data = data

    chart.radius = am4core.percent(60)
    chart.innerRadius = am4core.percent(35)
    chart.startAngle = 180
    chart.endAngle = 360

    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries())
    pieSeries.dataFields.value = "count"
    pieSeries.dataFields.category = "transport_type"

    pieSeries.slices.template.stroke = am4core.color("#fff")
    pieSeries.slices.template.strokeWidth = 2
    pieSeries.slices.template.strokeOpacity = 1

    //Disable Hover and Click for Slice
    // pieSeries.slices.template.states.getKey("hover").properties.scale = 1
    pieSeries.slices.template.states.getKey("active").properties.shiftRadius = 0

    //Tooltip
    pieSeries.tooltip.autoTextColor = false
    pieSeries.tooltip.label.fill = am4core.color("#122945")
    pieSeries.tooltip.label.fontSize = 14
    pieSeries.tooltip.label.fontWeight = 400

    pieSeries.tooltip.getFillFromObject = false
    pieSeries.tooltip.getStrokeFromObject = true
    pieSeries.tooltip.background.fill = am4core.color("#ffffff")

    pieSeries.slices.template.tooltipText =
      "Category: {category} \n Number of Seizures: {count} \n Percentage of Total: {value.percent.formatNumber('#.#')}%"

    // Set color
    let colors = []
    forEach(data, item => {
      colors.push(am4core.color(item.color))
    })

    pieSeries.colors.list = colors

    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 0
    pieSeries.hiddenState.properties.endAngle = 90
    pieSeries.hiddenState.properties.startAngle = 90

    pieSeries.alignLabels = true
    pieSeries.labels.template.padding(0, 0, 0, 0)
    pieSeries.labels.template.maxWidth = 80
    pieSeries.labels.template.wrap = true
    pieSeries.labels.template.fontSize = 14
    pieSeries.labels.template.disabled = true

    //Add legend
    // chart.legend = new am4charts.Legend();
    // chart.legend.position = "bottom";
    // let markerTemplate = chart.legend.markers.template;
    // markerTemplate.width = 15;
    // markerTemplate.height = 15;

    //Center label
    let label = pieSeries.createChild(am4core.Label)
    label.text = "{values.value.sum}"
    label.horizontalCenter = "middle"
    label.verticalCenter = "middle"
    label.fontSize = 30
    label.fontWeight = 600
    label.fill = am4core.color("#ff4d4f")

    removeAMChartMarker()
  }, [data])

  return <Holder id="transport_type_em_chart"></Holder>
}

const Holder = styled.div`
  width: 100%;
  height: 300px;
  @media ${device.mobileL} {
    height: 200px;
  }
`
