import { Equation, defaultErrorHandler } from "react-equation"
import styled from "styled-components"
import { useQuery } from "react-query"
import { useMeasure } from "react-use"
import { Dialog } from "primereact/dialog"
import ReactTooltip from "react-tooltip"
import ReactCountryFlag from "react-country-flag"
import { useStore } from "state/store"
import { device } from "theme/device"
import {
  getLocationTopSeizure,
  getTraffickingMethodProfile,
  getTraffickingOverTIme
} from "api"
import Loader from "components/Loader"
import TransportTypeHeader from "components/TransportTypeHeader"
import SeizureChart from "components/Charts/countryprofile/SeizureChart"
import SpecyHeader from "components/SpecyHeader"
import TransportTypeEmChart from "components/Charts/countryprofile/TransportTypeEmChart"

import InfoIcon from "assets/images/icon-info.svg"

import CountryNameMatches from "dataset/country_name_matches.json"
import { useMemo } from "react"
import { StartDateItems } from "dataset/sidebar"

export default function ProfileDialog() {
  const selectedCountryName = useStore(state => state.selectedCountryName)
  const openProfileDialog = useStore(state => state.openProfileDialog)
  const setOpenProfileDialog = useStore(state => state.setOpenProfileDialog)
  const profileGlobe = useStore(state => state.profileGlobe)

  const regionData = selectedCountryName

  const [seizureChartRef, seizureChartMeasurement] = useMeasure()

  const {
    data: profileData,
    error: pError,
    isError: pIsError,
    isLoading: pIsLoading
  } = useQuery(
    ["getLocationTopSeizure", regionData],
    () =>
      getLocationTopSeizure({
        region: regionData
      }),
    {
      keepPreviousData: true
    }
  )

  const {
    data: tmChartData,
    error: tmChartError,
    isError: tmChartIsError,
    isLoading: tmChartIsLoading
  } = useQuery(
    ["getTraffickingMethodProfile", regionData],
    () =>
      getTraffickingMethodProfile({
        region: regionData
      }),
    {
      keepPreviousData: true
    }
  )

  const {
    data: seizureChartData,
    error: seizureChartError,
    isError: seizureChartIsError,
    isLoading: seizureChartIsLoading
  } = useQuery(
    ["getTraffickingOverTIme", regionData],
    () =>
      getTraffickingOverTIme({
        region: regionData
      }),
    {
      keepPreviousData: true
    }
  )

  const filteredSeizureChartData = useMemo(() => {
    const result = []
    if (seizureChartData) {
      for (let i = 0; i < StartDateItems.length; i++) {
        const year = Number(StartDateItems[i].label)
        const idx = seizureChartData.findIndex(a => a.year === year)
        if (idx >= 0) {
          const item = seizureChartData[idx]
          result.push({
            year: item.year,
            Pangolin: item.Pangolin,
            Tiger: item.Tiger,
            Leopard: item.Leopard,
            Lion: item.Lion,
            Ivory: item.Ivory,
            "Rhino Horn": item["Rhino Horn"],
            "Ivory ": Math.round(item["Ivory "]),
            "Rhino Horn ": Math.round(item["Rhino Horn "]),
            "Pangolin Scales": Math.round(item["Pangolin Scales"])
          })
        } else {
          result.push({
            year,
            Pangolin: 0,
            Tiger: 0,
            Leopard: 0,
            Lion: 0,
            Ivory: 0,
            "Rhino Horn": 0,
            "Ivory ": 0,
            "Rhino Horn ": 0,
            "Pangolin Scales": 0
          })
        }
      }
    }
    return result
  }, [seizureChartData])

  const selectedCountryCode = useMemo(() => {
    const countryCode = CountryNameMatches.find(
      country => country.c4ads === selectedCountryName
    )?.code
    return countryCode
  }, [selectedCountryName])

  return (
    <StyledDialog
      header={
        <>
          <ReactCountryFlag
            countryCode={selectedCountryCode}
            svg
            className="flag"
          />{" "}
          {selectedCountryName}
        </>
      }
      visible={openProfileDialog}
      onHide={() => {
        setOpenProfileDialog(false)
        profileGlobe.goHome()
      }}
      draggable={false}
      resizeable={false}
      dismissableMask={true}
      modal={true}
    >
      <Content>
        <TopCitiesHolder>
          <PanelTitle>
            <span>Top Cities</span>
          </PanelTitle>
          <PanelContent>
            {pIsLoading ? (
              <Loader />
            ) : pIsError ? (
              pError
            ) : profileData?.topCities?.length > 0 ? (
              profileData?.topCities?.map((d, index) => {
                if (index > 2) return null
                return (
                  <Text key={index}>
                    {`${index + 1}. ${d.city} : `}
                    <span style={{ color: "red" }}>
                      {`${d.count} ${d.count > 1 ? "instances" : "instance"}`}
                    </span>
                  </Text>
                )
              })
            ) : (
              <NoData>No data available</NoData>
            )}
          </PanelContent>
        </TopCitiesHolder>
        <TopRoutesHolder>
          <PanelTitle>
            <span>Top Routes</span>
          </PanelTitle>
          <PanelContent>
            {pIsLoading ? (
              <Loader />
            ) : pIsError ? (
              pError
            ) : profileData?.topRoutes?.length > 0 ? (
              profileData?.topRoutes?.map((d, index) => {
                if (index > 2) return null
                return (
                  <Text key={index}>
                    {`${index + 1}. ${d.start_city} → ${d.end_city} : `}
                    <span style={{ color: "red" }}>
                      {`${d.count} ${d.count > 1 ? "instances" : "instance"}`}
                    </span>
                  </Text>
                )
              })
            ) : (
              <NoData>No data available</NoData>
            )}
          </PanelContent>
        </TopRoutesHolder>
        <CEIHolder>
          <PanelTitle>
            <span>Country Enforcement Index</span>
            <a data-tip data-for="CEI-Tooltip">
              <InfoImg src={InfoIcon} />
            </a>
          </PanelTitle>
          <PanelContent>
            {pIsLoading ? (
              <Loader />
            ) : pIsError ? (
              pError
            ) : profileData?.countryEnforcement?.percentage !== 0 ? (
              <StyledEquation
                value={`(${
                  profileData?.countryEnforcement?.seizedCount
                } seizures) / (${
                  Number(profileData?.countryEnforcement?.seizedCount) +
                  Number(profileData?.countryEnforcement?.seizeMissingCount)
                } possible seizures) = ${Math.round(
                  Number(profileData?.countryEnforcement?.percentage)?.toFixed(
                    2
                  )
                )}%`}
                errorHandler={defaultErrorHandler}
              />
            ) : (
              <NoData>No data available</NoData>
            )}
          </PanelContent>
        </CEIHolder>
        <TiSeizureChartHolder>
          <SpecyHeader />
          <PanelContent ref={seizureChartRef}>
            {seizureChartIsLoading ? (
              <Loader />
            ) : seizureChartIsError ? (
              seizureChartError
            ) : (
              <SeizureChart
                data={filteredSeizureChartData}
                width={seizureChartMeasurement.width - 20}
              />
            )}
          </PanelContent>
        </TiSeizureChartHolder>
        <TransportTypeChartHolder>
          <TransportTypeHeader />
          <PanelContent>
            {tmChartIsLoading ? (
              <Loader />
            ) : tmChartIsError ? (
              tmChartError
            ) : tmChartData.length > 0 ? (
              <TransportTypeEmChart data={tmChartData} />
            ) : (
              <NoData>No data available</NoData>
            )}
          </PanelContent>
        </TransportTypeChartHolder>
      </Content>
      <ReactTooltip
        id="CEI-Tooltip"
        type="warning"
        effect="solid"
        backgroundColor="#061830"
      >
        <span>
          A possible seizure refers to an illicit wildlife shipment that passed
          through the country, regardless of whether it was intercepted there or
          seized at another location.
        </span>
      </ReactTooltip>
      <ReactTooltip
        id="OverTime-Tooltip"
        type="warning"
        effect="solid"
        backgroundColor="#061830"
      >
        <span>
          If multiple categories of wildlife were intercepted in the same
          seizure, the incident will be represented in the graphics as multiple
          seizures. For example, if ivory and rhino horn were seized together,
          it will be counted as a total of two seizures. Please refer to the
          Over Time page to view total seizure counts.
          <br />
          <br />
          This graph displays the number of seizures that occurred in the
          selected country by year.
        </span>
      </ReactTooltip>
      <ReactTooltip
        id="Method-Tooltip"
        type="warning"
        effect="solid"
        backgroundColor="#061830"
      >
        <span>
          This graph displays the transport types used in seizures linked to
          this country, regardless of whether or not the seizure occurred in
          this country.
        </span>
      </ReactTooltip>
    </StyledDialog>
  )
}

const StyledDialog = styled(Dialog)`
  width: 80%;
  @media ${device.mobileL} {
    width: 95%;
  }
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5em;
`

const PanelTitle = styled.div`
  position: relative;
  font-size: 1em;
  color: #144a68;
  font-weight: bold;
  padding: 0.5em;
  background: #f3f9fd;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const PanelContent = styled.div`
  padding: 1em;
`

const Text = styled.h6`
  font-size: 0.9em;
  line-height: 170%;
  margin: 0.2em 0;
  padding: 0;
  font-weight: normal;
`

const Panel = styled.div`
  border: 1px solid #cde2ef;
  background-color: #fefefe;
`

const TopCitiesHolder = styled(Panel)`
  width: 31%;
  min-width: 350px;
  height: 250px;
`

const TopRoutesHolder = styled(Panel)`
  width: 31%;
  min-width: 350px;
  height: 250px;
`

const CEIHolder = styled(Panel)`
  width: 31%;
  min-width: 350px;
  height: 250px;
  ${PanelContent} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const TiSeizureChartHolder = styled(Panel)`
  width: 57%;
  height: fit-content;
  ${PanelContent} {
    display: flex;
    justify-content: center;
  }
  @media ${device.pad} {
    width: 100%;
  }
`

const TransportTypeChartHolder = styled(Panel)`
  width: 38%;
  ${PanelContent} {
    display: flex;
    justify-content: center;
  }
  @media ${device.pad} {
    width: 100%;
  }
`

const InfoImg = styled.img`
  width: 1.5em;
  height: 1.5em;
`

const NoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  font-size: 1em;
`

const StyledEquation = styled(Equation)`
  font-size: 1.3em;
  margin-top: 2em;
`
