import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts"
import { SpecyLegendData, SpecyWeightLegendData } from "dataset/legend"

export default function TraffickingTimeChart({
  data = [],
  activeWeight = false
}) {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        width={300}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 20,
          left: 20,
          bottom: 20
        }}
      >
        <Tooltip />
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="year"
          label={{
            value: "Years",
            position: "insideBottom",
            offset: -15,
            fontSize: "0.9em",
            fill: "#939393"
          }}
        />
        {activeWeight ? (
          <YAxis
            orientation="left"
            dx={-5}
            label={{
              value: "Weight of Seizures (kg)",
              angle: -90,
              position: "insideLeft",
              dy: 60,
              dx: -15,
              fontSize: "0.9em",
              fill: "#939393"
            }}
          />
        ) : (
          <YAxis
            orientation="left"
            dx={-5}
            label={{
              value: "Number of Seizures",
              angle: -90,
              position: "insideLeft",
              dy: 50,
              dx: -5,
              fontSize: "0.9em",
              fill: "#939393"
            }}
          />
        )}
        {activeWeight
          ? SpecyWeightLegendData.map((item, index) => (
              <Bar
                key={index}
                dataKey={item.label}
                stackId="a"
                fill={item.color}
              />
            ))
          : SpecyLegendData.map((item, index) => (
              <Bar
                key={index}
                dataKey={item.label}
                stackId="a"
                fill={item.color}
              />
            ))}
      </BarChart>
    </ResponsiveContainer>
  )
}
