import { groupBy } from "lodash"
import mapData from "dataset/states-list-with-continents.json"
import { getCountryMap } from "api"

const stackData = [
  { label: "Air", value: "AIR" },
  { label: "Land", value: "LAND" },
  { label: "Mail", value: "MAIL" },
  { label: "Sea", value: "SEA" }
]

const species = [
  // { label: "All", value: "" },
  { label: "Ivory", value: "ivory" },
  { label: "Leopard", value: "leopard" },
  { label: "Lion", value: "lion" },
  { label: "Pangolin", value: "pangolin" },
  { label: "Rhino Horn", value: "rhino" },
  { label: "Tiger", value: "tiger" }
]

const medias = [
  // { label: "All", value: "" },
  { label: "Air", value: "air" },
  { label: "Land", value: "land" },
  { label: "Mail", value: "mail" },
  { label: "Sea", value: "sea" }
  // { label: "Unknown", value: "unknown" }
]

let countries = []

let groups = []

let regions = []

getCountryMap().then(res => {
  //countries
  mapData.forEach(feature => {
    if (res.findIndex(c => c.cads_name === feature.state) !== -1) {
      const label =
        feature.state === "USA"
          ? "United States"
          : feature.state === "UK"
          ? "United Kingdom"
          : feature.state

      countries.push({
        label: label,
        value: feature.state,
        region: feature.continent,
        title: feature.state,
        key: feature.state
      })
    }
  })

  countries.sort((a, b) => a.label.localeCompare(b.label))

  //groups
  groups = groupBy(countries, "region")

  //regions
  for (const key in groups) {
    if (key && key !== "undefined") {
      regions.push({
        key: key,
        label: key,
        data: key,
        title: key,
        value: key,
        children: groups[key]
      })
    }
  }
})

export { regions, countries, stackData, species, medias }
