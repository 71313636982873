import SwitchSelector from "react-switch-selector"

const options = [
  {
    label: "No. of Seizures",
    value: 0,
    fontColor: "#122945",
    selectedFontColor: "#ffffff",
    backgroundColor: "#F3F9FD",
    selectedBackgroundColor: "#33637A"
  },
  {
    label: "Weight of Seizures",
    value: 1,
    fontColor: "#122945",
    selectedFontColor: "#ffffff",
    backgroundColor: "#F3F9FD",
    selectedBackgroundColor: "#33637A"
  }
]

const initialSelectedIndex = options.findIndex(({ value }) => value === 0)

export default function LetterToggle({ className, onChange }) {
  return (
    <div
      className={className}
      style={{ width: 300, height: 30, minHeight: 30 }}
    >
      <SwitchSelector
        onChange={onChange}
        options={options}
        initialSelectedIndex={initialSelectedIndex}
      />
    </div>
  )
}
