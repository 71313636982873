import { useEffect } from "react"
import styled from "styled-components"
import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import { useMatomo } from "@datapunt/matomo-tracker-react"

import { useStore } from "state/store"
import { device } from "theme/device"
import { getInstanceCountCountryCount } from "api"

import { MiddleContainer } from "components/Containers"
import Button from "components/Button"

import BackImg from "assets/images/globe.png"
import PangolinImg from "assets/images/pangolin.svg"
import TigerImg from "assets/images/tiger.svg"
import LeopardImg from "assets/images/leopard.svg"
import LionImg from "assets/images/lion.svg"
import ElephantImg from "assets/images/elephant.svg"
import RhinoImg from "assets/images/rhino.svg"
import { addCommaToNumber } from "utils"

const speciesData = [
  {
    type: "elephant",
    img: ElephantImg
  },
  {
    type: "leopard",
    img: LeopardImg
  },
  {
    type: "lion",
    img: LionImg
  },
  {
    type: "pangolin",
    img: PangolinImg
  },
  {
    type: "rhino",
    img: RhinoImg
  },
  {
    type: "tiger",
    img: TigerImg
  }
]

export default function Landing() {
  const { trackPageView } = useMatomo()
  const setSideBarVisible = useStore(state => state.setSideBarVisible)

  const { data: reactData } = useQuery(
    ["getInstanceCountCountryCount"],
    () => getInstanceCountCountryCount(),
    { keepPreviousData: true }
  )

  useEffect(() => {
    trackPageView({
      documentTitle: window.location.pathname + window.location.search
    })
    setSideBarVisible(false)
    // eslint-disable-next-line
  }, [])

  return (
    <Holder>
      <SpecieList>
        {speciesData.map(({ img }, index) => (
          <Specie key={index} img={img} />
        ))}
      </SpecieList>
      <Heading>C4ADS Wildlife Seizure Dashboard</Heading>
      <Desc>
        The C4ADS Wildlife Seizure Dashboard showcases trends in wildlife
        seizures worldwide from 2013 to the present. As of today, the C4ADS
        Wildlife Seizure Dashboard includes data on{" "}
        <b>{addCommaToNumber(reactData?.instanceCount)}</b> seizures spanning{" "}
        <b>{addCommaToNumber(reactData?.countryCount)}</b> jurisdictions for six
        frequently-trafficked wildlife categories: elephant ivory, leopard,
        lion, pangolin, rhino horn, and tiger.
      </Desc>
      <Link to="/home">
        <Button width={264}>Get Started</Button>
      </Link>
    </Holder>
  )
}

const Holder = styled(MiddleContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
  max-width: 100%;
  width: 100%;
  padding: 5em 2em;
  background-image: url(${BackImg});
  background-size: 90% auto;
  background-position: center;
  background-repeat: no-repeat;
  @media ${device.mobileL} {
    background-size: auto 80%;
  }
`

const SpecieList = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.8em;
  @media ${device.mobileL} {
    gap: 1.2em;
  }
`

const Specie = styled.div`
  width: 5.5em;
  height: 5.5em;
  border-radius: 50%;
  background: #ffffff;
  border: 2px solid #479577;
  box-sizing: border-box;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.15);
  background-image: url(${props => props.img});
  background-size: 70% 70%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 300ms ease;
  @media ${device.mobileL} {
    width: 2.6em;
    height: 2.6em;
  }
`

const Heading = styled.h2`
  font-weight: bold;
  font-size: 2.5em;
  line-height: 120%;
  color: #144a68;
  padding: 0;
  margin-top: 1.3em;
  text-align: center;
  @media ${device.mobileL} {
    font-size: 1.85em;
  }
`

const Desc = styled.div`
  max-width: 800px;
  font-size: 1.1em;
  line-height: 1.5em;
  text-align: center;
  letter-spacing: 0.02em;
  color: #144a68;
  margin: 1.8em auto;
  @media ${device.mobileL} {
    font-size: 1em;
  }
`
