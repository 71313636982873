import { useEffect } from "react"
import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"
import { useQuery } from "react-query"
import { useMatomo } from "@datapunt/matomo-tracker-react"

import { getRegionDataAvailability } from "api"
import { useStore } from "state/store"
import {
  convert2Method,
  convert2Region,
  convert2Specie
} from "utils/convertDataFormat"

import { FAQ, SLDesc, SLTitle } from "components/SeveralLetters"
import AboutDataAvailabilityChart from "components/Charts/AboutDataAvailabilityChart"
import ExpandedPanel from "components/ExpandedPanel"
import Button from "components/Button"
import Loader from "components/Loader"
import OutlineButton from "components/OutlineButton"

import BackImage from "assets/images/globe.png"
import ArrowBackIcon from "assets/images/icon-arrow-back.svg"
import WWImage from "assets/images/home/worldwide.svg"
import ExportImage from "assets/images/home/export.svg"
import FilterImage from "assets/images/home/filter.svg"
import DataCollectionImage from "assets/images/home/data-collection.svg"
import WDPLogo from "assets/images/logo-wdp.svg"
import WildlifeSeizureDashLogo from "assets/images/logo-wildlife-seizure.svg"

export default function About({ history }) {
  const { trackPageView } = useMatomo()
  const selectedSpecie = useStore(state => state.selectedSpecie)
  const selectedMethod = useStore(state => state.selectedMethod)
  const selectedRegion = useStore(state => state.selectedRegion)
  const selectedDuringFrom = useStore(state => state.selectedDuringFrom)
  const selectedDuringTo = useStore(state => state.selectedDuringTo)

  const specieData = convert2Specie(selectedSpecie)
  const methodData = convert2Method(selectedMethod)
  const regionData = convert2Region(selectedRegion)

  const {
    data: barChartData,
    error: barChartError,
    isError: barChartIsError,
    isLoading: barChartIsLoading
  } = useQuery(
    [
      "getRegionDataAvailability",
      specieData,
      methodData,
      selectedDuringFrom,
      selectedDuringTo,
      regionData
    ],
    () =>
      getRegionDataAvailability({
        analyze: specieData,
        transport_type: methodData,
        date_from: selectedDuringFrom,
        date_to: selectedDuringTo,
        region: regionData
      }),
    { keepPreviousData: true }
  )

  useEffect(() => {
    trackPageView({
      documentTitle: window.location.pathname + window.location.search
    })
  }, [trackPageView])

  return (
    <AnimatePresence>
      <AboutHolder
        transition={{ duration: 0.5 }}
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 30 }}
      >
        <BackButton
          icon={ArrowBackIcon}
          onClick={() => {
            history.goBack()
          }}
        >
          Back
        </BackButton>
        <Brands paddingTop="0em" paddingBottom="1em">
          <ResizedLeftAlignedImage src={WildlifeSeizureDashLogo} alt="" />
        </Brands>
        <SLTitle>About the Dashboard</SLTitle>
        <SLDesc>
          The C4ADS Wildlife Seizure Dashboard provides users with the ability
          to independently view, filter, and analyze wildlife seizure data at a
          global scale and across transportation sectors. b. With data derived
          from the C4ADS Wildlife Seizure Database, the C4ADS Wildlife Seizure
          Dashboard gives users the opportunity to engage directly with
          information on over 7,000 publicly reported seizures across six
          categories of high-value, high-demand wildlife: elephant ivory, rhino
          horn, pangolin, leopard, lion, and tiger.
          <br />
          <br />
          The power of the C4ADS Wildlife Seizure Dashboard is rooted in the
          quality and the quantity of its data, and is amplified by the
          incorporation of customizable searches to narrow down data to reflect
          countries, species, transportation methods, and/or years of interest.
          By leveraging the filtered data analytics contained herein, C4ADS
          Wildlife Seizure Dashboard can highlight general trends in illegal
          wildlife trafficking, aid decision makers in the formulation of
          policy, provide insight and context to journalists investigating
          illicit trafficking activities, and even assist law enforcement
          officers as they calibrate resources to address shifting trends. As a
          result, C4ADS Wildlife Seizure Dashboard is a key resource for
          increasing understanding about the shifting dynamics of the illegal
          wildlife trade while serving to promote actionable data to
          counter-wildlife trafficking stakeholders.
        </SLDesc>
        <ExpandedPanel collapsed={false} header="Contact Us">
          <ContactHolder>
            <div>
              Notice an issue with the data, have data to contribute, or
              interested in best practices to start your own seizure database?
              Reach out to us at wildlife@c4ads.org.
            </div>
            <a
              target="_blank"
              href="mailto:wildlife@c4ads.org"
              rel="noopener noreferrer"
            >
              <ContactButton>Contact Us</ContactButton>
            </a>
          </ContactHolder>
        </ExpandedPanel>
        <ExpandedPanel header="How to Use the C4ADS Wildlife Seizure Dashboard">
          <MethodDescItem>
            <div>
              <img src={WWImage} alt="" />
            </div>
            <div>
              <h6>View trends in wildlife seizures worldwide</h6>
              <p>
                Navigate across the top menu to view data visualizations and
                narratives pertaining to different categories of seizure
                analysis including transport type, location, and country
                profiles.
              </p>
            </div>
          </MethodDescItem>
          <MethodDescItem>
            <div>
              <img src={FilterImage} alt="" />
            </div>
            <div>
              <h6>
                Filter the data by wildlife category, transportation method,
                year, and country.
              </h6>
              <p>
                Generate custom graphics by selecting filters to narrow the
                range of data. As each filter is selected, the data displayed in
                the graphics of each tab will become more refined according to
                the filter specifications. Multiple wildlife categories,
                transportation methods, and countries can be selected at one
                time. Filter selections can also be changed or added at any
                time. To remove filters, select “Reset All Filters.”
              </p>
            </div>
          </MethodDescItem>
          <MethodDescItem>
            <div>
              <img src={ExportImage} alt="" />
            </div>
            <div>
              <h6>Export custom graphics</h6>
              <p>
                After selecting the desired filters, select the “download” icon
                in the top right corner of the graphic in order to export it as
                a PDF. If using these graphics in a publication, please cite the
                C4ADS Wildlife Seizure Dashboard (see “How to Cite the C4ADS
                Wildlife Seizure Dashboard” below).
              </p>
            </div>
          </MethodDescItem>
          <p>
            For more information about how to use the C4ADS Wildlife Seizure
            Dashboard, please see the video tutorials, offered in{" "}
            <a
              href="https://www.youtube.com/watch?v=K-OrjaaUMcE&t=7s"
              target="_blank"
              rel="noreferrer"
            >
              English
            </a>{" "}
            and{" "}
            <a
              href="https://www.youtube.com/watch?v=dDMdOrK7sO4&t=884s"
              target="_blank"
              rel="noreferrer"
            >
              French
            </a>
            , on C4ADS’ YouTube page.
          </p>
        </ExpandedPanel>
        <ExpandedPanel header="Data Collection Methodology">
          <SLDesc>
            The C4ADS Wildlife Seizure Database is compiled through the
            extensive collection and structuring of publicly available reporting
            on wildlife trafficking. C4ADS monitors news media, customs
            websites, reports, and other digital sources in over 15 languages on
            a weekly basis to identify reports of wildlife seizures and add them
            to the database. Every seizure, regardless of if multiple types of
            wildlife are seized together, is recorded as one row in the
            database. More than 90 fields of information can be recorded for
            each seizure, including: type and quantity of seized wildlife;
            origin, transit, and destination locations; obfuscation methods;
            airports and seaports used to move the seized products; and
            individuals and networks implicated in the seizure event. Once
            structured, each row of seizure data then goes through a three-step
            cleaning and integration process to ensure its accuracy.
          </SLDesc>
          <FigHolder>
            <img
              src={DataCollectionImage}
              alt=""
              style={{ width: "100%", maxWidth: "900px" }}
            />
            <p>
              The C4ADS Wildlife Seizure Dashboard’s Data Collection Process
            </p>
          </FigHolder>
          <SLDesc>
            In addition to integration in the C4ADS Wildlife Seizure Dashboard,
            each seizure is integrated into C4ADS’ Centralized Wildlife
            Trafficking Investigative Database (CWT-ID), housed in Palantir
            Gotham. Within the CWT-ID, copies of the source documents for each
            seizure are archived. As a result, even if the web address for the
            source becomes invalid, the data’s provenance will still be
            preserved within Palantir Gotham.
          </SLDesc>
          <SLDesc>
            Due to maintaining a strong data lineage for each seizure, the data
            contained within the C4ADS Wildlife Seizure Database has been able
            to go through a series of overhauls and updates to improve its
            analytical utility and accuracy. Any changes to historical data are
            automatically updated on the C4ADS Wildlife Seizure Dashboard.
          </SLDesc>
        </ExpandedPanel>
        <ExpandedPanel header="Data Limitations">
          <SLDesc>
            Given that the C4ADS Wildlife Seizure Database contains information
            drawn from publicly available reporting, a few caveats must be
            considered for analysis:
          </SLDesc>
          <NumberListText>
            <li>
              As with all datasets derived from publicly available reporting,
              quality of data varies based on differences in coverage, data
              access, and reporting requirements. Local news coverage of seizure
              events in particular may vary based on public interest in wildlife
              crime.
            </li>
            <li>
              C4ADS analysts work to minimize inconsistencies in reporting by
              drawing on multiple sources and, where possible, consulting with
              local contacts in order to obtain as much information as is
              available. However, it remains possible that the data sourced from
              publicly available information, such as news media or customs
              reports, may contain incomplete information about the seizure
              instance or contain reporting errors.
            </li>
            <li>
              An increase or decrease of seizures in a particular geographic
              area or of a particular category of wildlife is not necessarily
              indicative of an increase or decrease in trafficking activity, but
              rather could be indicative of a change in proactive reporting of
              seizures, a fluctuation in law enforcement capacity, or a
              combination thereof or of other variables.
            </li>
            <li>
              Seizure data inherently only captures details about failed
              trafficking attempts. As a result, there may be successful
              transportation routes, methods, and other tactics being used by
              traffickers that are not represented in the database. Therefore,
              the results displayed on the analysis pages of the C4ADS Wildlife
              Seizure Dashboard are not meant to represent the entirety of
              wildlife trafficking activity, but rather are intended to showcase
              the patterns evident within the C4ADS Wildlife Seizure Database.
            </li>
          </NumberListText>
          <FigHolder>
            <ChartWrapper>
              {barChartIsLoading ? (
                <Loader />
              ) : barChartIsError ? (
                barChartError
              ) : (
                <AboutDataAvailabilityChart data={barChartData} />
              )}
            </ChartWrapper>
            <p>
              Data Availability in the C4ADS Wildlife Seizure Database (Based on
              Filters)
            </p>
          </FigHolder>
          <SLDesc>
            Despite these limitations, seizure data remains the best proxy
            available for evaluating global patterns in illicit wildlife
            trafficking. Through the comprehensive collection and analysis of
            seizure data, it is possible to trace the development of trends,
            assess the relative significance of different typologies, and
            develop a more holistic picture of the mechanics of global wildlife
            trafficking. In this way, seizure data adds valuable qualitative and
            quantitative context to the otherwise clandestine world of illicit
            wildlife trafficking.
          </SLDesc>
        </ExpandedPanel>
        <ExpandedPanel header="FAQ">
          <FAQ>
            <h2>
              Q: What is the difference between the number of “seizures” and the
              number of “country-linked seizures” for a jurisdiction(s)?
            </h2>
            <p>
              A seizure count is the number of interdictions of wildlife or
              wildlife product in a given location, whereas a country-linked
              seizure count refers to the number of incidents of wildlife
              trafficking that originated in, transited through, or were
              destined for a specific country, regardless of whether or not the
              incident of wildlife trafficking was interdicted in that country.
              The county-linked seizure count of a country is therefore always
              equal to or greater than the seizure count of the same country.
            </p>
            <p>
              For example, if a shipment of rhino horn originating in South
              Africa and destined for Vietnam is seized in transit at an airport
              in Turkey, Turkey would have a count of 1 for seizures and 1 for
              country-linked seizures; South Africa would have a count of 0 for
              seizures and 1 for country-linked seizures, and Vietnam would have
              a count of 0 for seizures and 1 for country-linked seizure.
            </p>
          </FAQ>
          <FAQ>
            <h2>
              Q: Why is the number of “country-linked seizures” different than
              the number of “possible seizures?”
            </h2>
            <p>
              A country-linked seizure count refers to the number of incidents
              of wildlife trafficking that originated in, transited through, or
              were destined for a specific country, regardless of whether or not
              they were interdicted in that country. However, in some cases, a
              shipment of wildlife may be destined to pass through a country,
              but was seized before arriving. In that case, a country would have
              had “no opportunity” to seized the wildlife shipment. Therefore,
              the number of country-linked seizures for a country is always
              equal to or higher than the number of “possible seizures.”
              <br />
              For example, if a shipment of rhino horn originating in South
              Africa and destined for Vietnam is seized in transit at an airport
              in Istanbul, the incident would be classified as a seizure for
              Turkey, a country-linked seizure for all three countries, and a
              possible seizure for both South Africa and Turkey.
            </p>
          </FAQ>
          <FAQ>
            <h2>Q: Is other seizure data available? How can I access it?</h2>
            <p>
              Yes. More than 90 fields of information can be recorded for each
              seizure. However, at this time, the C4ADS Wildlife Seizure
              Dashboard displays only analysis of top-line trends. If you would
              like to request data that is not currently present on the C4ADS
              Wildlife Seizure Dashboard, please fill out the data request form
              located{" "}
              <a
                href="https://form.jotform.com/221025675701045"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </p>
          </FAQ>
          <FAQ>
            <h2>
              Q: I noticed a seizure that is missing from your database. How can
              I provide information about the seizure?
            </h2>
            <p>
              If you are aware of a seizure that is missing and meets our
              criteria (a seizure taking place from 2013 to present of elephant
              ivory, rhino horn, pangolin, leopard, lion, and/or tiger), please
              email wildlife@c4ads.org to provide details of the seizure you
              would like to contribute. If possible, please include the date,
              location, contents, route of the seized wildlife, and a link to
              the original report of the seizure. We may request follow-up
              information regarding the seizure.
            </p>
          </FAQ>
          <FAQ>
            <h2>
              Q: I calculated trends in the past using the C4ADS Wildlife
              Seizure Dashboard and recalculated them today, but the results
              were different. Why is that?
            </h2>
            <p>
              The C4ADS Wildlife Seizure Dashboard is frequently updated to
              include new seizures or add details to existing historic wildlife
              seizure data. As a result, the statistics and trends displayed by
              the C4ADS Wildlife Seizure Dashboard are constantly evolving in
              order to most accurately portray the current state of illicit
              wildlife seizures worldwide
            </p>
          </FAQ>
          <FAQ>
            <h2>Q: How frequently is the data updated?</h2>
            <p>
              We collect new seizure data on a weekly basis, routinely adding to
              and revising the underlying data of the C4ADS Wildlife Seizure
              Dashboard. These data additions are then automatically synced to
              the C4ADS Wildlife Seizure Dashboard on a daily basis.
            </p>
          </FAQ>
          <FAQ>
            <h2>
              Q: Can I use the graphics generated on the C4ADS Wildlife Seizure
              Dashboard in my own publications?
            </h2>
            <p>
              Yes. Please refer to the guidance of “How to Cite the C4ADS
              Wildlife Seizure Dashboard” (located on this “About the Dashboard”
              page) if you wish to use graphics or analysis generated by the
              C4ADS Wildlife Seizure Dashboard in a publication.
            </p>
          </FAQ>
          <FAQ>
            <h2>
              Q: My question was not answered by the C4ADS Wildlife Seizure
              Dashboard. How can I get it answered?
            </h2>
            <p>
              Please email wildlife@c4ads.org with your question about the C4ADS
              Wildlife Seizure Database or Dashboard, and we will do our best to
              answer your inquiry.
            </p>
          </FAQ>
          <FAQ>
            <h2>
              Q: I am part of a counter-wildlife trafficking or law enforcement
              organization, and would like to become a partner of C4ADS’
              Wildlife Defense Platform. How can I join?
            </h2>
            <p>
              Please email wildlife@c4ads.org to provide details about your
              organization and interest in the Wildlife Defense Platform. We are
              happy to discuss your wildlife seizure data, data management
              practices, and potential for inclusion in the forthcoming Wildlife
              Defense Platform.
            </p>
          </FAQ>
          <FAQ>
            <h2>
              Q: How does the C4ADS Wildlife Seizure Dashboard differ from the
              ROUTES Dashboard produced by C4ADS as part of the USAID Reducing
              Opportunities for Unlawful Transport of Endangered Species
              (ROUTES) Partnership?
            </h2>
            <p>
              The ROUTES Dashboard provided interactive graphics displaying
              wildlife seizure data from 2009-2020 pertaining exclusively to the
              air transport sector, but encompassing all species of wildlife.
              The C4ADS Wildlife Seizure Dashboard’s scope pertains to all
              transportation sectors and six key categories of frequently seized
              wildlife (elephant ivory, rhino horn, pangolin, leopard, lion, and
              tiger) during the period of 2013 to present.
            </p>
          </FAQ>
        </ExpandedPanel>
        <ExpandedPanel header="How to Cite the C4ADS Wildlife Seizure Dashboard">
          <SLDesc>
            <b>General:</b> Please refer to this tool as the “C4ADS Wildlife
            Seizure Dashboard” and the data as the “C4ADS Wildlife Seizure
            Database.”
            <br />
            <br />
            <b>APA Style:</b> Center for Advanced Defense Studies (C4ADS). (Date
            Queried: YYYY, Month DD). C4ADS <i>Wildlife Seizure Dashboard.</i>{" "}
            URL
          </SLDesc>
        </ExpandedPanel>
        <ExpandedPanel
          header="About C4ADS’ Wildlife Crimes Program"
          isFinal={true}
        >
          <SLDesc>
            C4ADS’ Wildlife Crimes Program uses data-driven analysis and
            evidence-based reporting to support global efforts to detect and
            disrupt illicit wildlife trafficking activity. C4ADS’ Wildlife
            Crimes Program works with law enforcement, the private sector, and
            civil society organizations around the world to provide
            investigative support and capacity building. Furthermore, C4ADS’
            Wildlife Crimes Program bridges gaps in data sharing in the
            counter-wildlife trafficking space by facilitating data management,
            identifying convergence across its partner network’s investigations,
            and facilitating collaboration across jurisdictions.
            <br />
            <br />
            Additionally, the program developed and maintains the C4ADS Wildlife
            Seizure Database to monitor global wildlife trafficking trends and
            to contextualize country-specific analysis of wildlife trafficking
            activity. C4ADS’ Wildlife Crimes Program created the C4ADS Wildlife
            Seizure Dashboard to place its collection of seizure data into the
            hands of as many individuals as possible who can use the data and
            derived analysis to generate positive action against wildlife crime.
            To learn more about C4ADS’ Wildlife Crimes Program, please view its
            page on the C4ADS{" "}
            <a
              target="_blank"
              href="https://c4ads.org/environmental-crimes-fusion-cell"
              style={{ color: "#144a68", textDecoration: "underline" }}
              rel="noreferrer"
            >
              website
            </a>{" "}
            or reach out via wildlife@c4ads.org.
          </SLDesc>
        </ExpandedPanel>
        <LineDiv />
        <SubHolder>
          <Brands>
            <ResizedLeftAlignedImage src={WDPLogo} alt="" width="286.78px" />
          </Brands>
          <SLTitle>About the Wildlife Defense Platform</SLTitle>
          <SLDesc>
            The Wildlife Defense Platform (WDP) is a customizable dashboard that
            enables partner organizations to quickly visualize and analyze their
            own private seizure and courtroom monitoring data.
            <br />
            <br />
            WDP users can efficiently browse through the collected data and spot
            cases of interest by using its tables and built-in advanced
            filtering capabilities. They can also easily identify trends at a
            glance or create visualizations for regular reports thanks to its
            flexible charts and maps building system.
            <br />
            <br />
            The WDP acts as a resource multiplier for its users as it greatly
            reduces the time required to extract, clean, process, sort, and
            visualize seizure and courtroom monitoring data. This will enable
            our partners to better leverage the data they are collecting as the
            convenience offered by the WDP will allow them to focus their
            attention on advanced analysis and exploiting the generated insight
            to further their impact.
            <br />
            <br />
            <strong> Explore:</strong>{" "}
            <a
              href="https://c4ads.org/news/c4ads-launches-new-wildlife-defense-platform/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://c4ads.org/news/c4ads-launches-new-wildlife-defense-platform/
            </a>
          </SLDesc>
        </SubHolder>
      </AboutHolder>
    </AnimatePresence>
  )
}

const AboutHolder = styled(motion.div)`
  width: 100%;
  background-color: #ffffffcc;
  padding: 2em;
  background-image: url(${BackImage});
  background-size: 100% auto;
  background-repeat: no-repeat;
`
const BackButton = styled(OutlineButton)`
  width: 10em;
  margin-bottom: 2em;
`

const SubHolder = styled.div`
  margin-top: 2em;
`

const MethodDescItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
  margin-bottom: 1em;
  text-align: justify;
  div {
    h6 {
      font-weight: bold;
      color: #144a68;
      font-size: 1.1em;
      margin-top: 0.3em;
    }
    p {
      color: #144a68;
    }
  }
`
const FigHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  margin: 2em 0;
  p {
    color: #144a68;
    font-weight: bold;
  }
`
const NumberListText = styled.ul`
  list-style-type: decimal;
  color: #144a68;
  margin: 0;
  padding: 0;
  padding-left: 1em;
  text-align: justify;
`
const Brands = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: ${props => props.paddingTop || "2em"};
  padding-bottom: ${props => props.paddingBottom || "2em"};
`
const ChartWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 1em;
  display: flex;
  flex-direction: column;
`
const ContactHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 3em;
`
const ContactButton = styled(Button)`
  min-width: 10em;
  width: 10em;
`
const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  background-color: #a0b9d0;
`
const ResizedLeftAlignedImage = styled.img`
  float: left;
  width: ${props => props.width || "279.25px"};
  clear: both;
`
