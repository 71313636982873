import React from "react"
import { Panel } from "primereact/panel"
import { Ripple } from "primereact/ripple"

export default function ExpandedPanel({ children, header, collapsed = true }) {
  const template = options => {
    const toggleIcon = options.collapsed
      ? "pi pi-chevron-down"
      : "pi pi-chevron-up"
    const className = `${options.className} justify-content-space-between`
    const titleClassName = `${options.titleClassName} pl-1`

    return (
      <div
        className={className}
        style={{
          border: "1px solid #A0B9D0",
          borderRadius: "0",
          background: "#FFFFFF"
        }}
      >
        <span
          className={titleClassName}
          style={{ color: "#144A68", fontSize: "1.4em" }}
        >
          {header}
        </span>
        <button
          className={options.togglerClassName}
          onClick={options.onTogglerClick}
        >
          <span className={toggleIcon}></span>
          <Ripple />
        </button>
      </div>
    )
  }

  return (
    <Panel headerTemplate={template} toggleable collapsed={collapsed}>
      {children}
    </Panel>
  )
}
