import styled, { keyframes } from "styled-components"

export default function Loader() {
  return (
    <LoaderHolder>
      <Spinner viewBox="0 0 50 50">
        <Path cx="25" cy="25" r="20" fill="none" strokeWidth="5"></Path>
      </Spinner>
    </LoaderHolder>
  )
}

const LoaderHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`
const rotate = keyframes`
  100% {
      transform: rotate(360deg);
  }
`
const Spinner = styled.svg`
  animation: ${rotate} 2s linear infinite;
  z-index: 2;
  width: 80px;
  height: 80px;
  margin-bottom: 1rem;
`
const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`
const Path = styled.circle`
  stroke: ${({ theme }) => theme.colors.secondary};
  stroke-linecap: round;
  animation: ${dash} 1.5s ease-in-out infinite;
`
