import { useEffect, useMemo, useState } from "react"
import { SideContainer } from "components/Containers"
import { useStore } from "state/store"
import { useMatomo } from "@datapunt/matomo-tracker-react"
import { useQuery } from "react-query"
import {
  getProportionTransportType,
  getAverageWeightTransportType,
  getSeizureWeightTransportType,
  getTotalWeightTransportType,
  getTextTransportType
} from "api"
import Loader from "components/Loader"
import AverageTable from "./AverageTable"
import styled from "styled-components"
import TransportTypePieChart from "components/Charts/TransportTypePieChart"
import TransportTypeScatterChart from "components/Charts/TransportTypeScatterChart"
import TransportStackChart from "components/Charts/TransportStackChart"
import {
  convert2Region,
  convert2Method,
  convert2Specie
} from "utils/convertDataFormat"
import Card from "components/Card"
import {
  SLDesc,
  SLTips,
  SLTitle,
  SLReactText,
  ChartDesc
} from "components/SeveralLetters"
import { countries, regions, stackData } from "dataset/filters"
import { replaceAndText } from "utils"
import LetterToggle from "components/LetterToggle"

export default function TransportType() {
  const { trackPageView } = useMatomo()
  const setSideBarVisible = useStore(state => state.setSideBarVisible)

  const selectedSpecie = useStore(state => state.selectedSpecie)
  const selectedMethod = useStore(state => state.selectedMethod)
  const selectedRegion = useStore(state => state.selectedRegion)
  const selectedDuringFrom = useStore(state => state.selectedDuringFrom)
  const selectedDuringTo = useStore(state => state.selectedDuringTo)

  const specieData = convert2Specie(selectedSpecie)
  const methodData = convert2Method(selectedMethod)
  const regionData = convert2Region(selectedRegion)

  const [reactTransportType, setReactTransportType] = useState("")
  const [reactSpecy, setReactSpecy] = useState("wildlife product")
  const [reactRegion, setReactRegion] = useState("")
  const [isWeightSpecy, setIsWeightSpecy] = useState(true)

  const [activeBarChartWeight, setActiveBarChartWeight] = useState(0)

  const handleBarChartToggleChange = v => {
    setActiveBarChartWeight(v)
  }

  //Pie chart data
  const {
    data: pieChartData,
    error: pieChartError,
    isError: isPieChartError,
    isLoading: isPieChartLoading
  } = useQuery(
    [
      "getProportionTransportType",
      specieData,
      methodData,
      selectedDuringFrom,
      selectedDuringTo,
      regionData
    ],
    () =>
      getProportionTransportType({
        analyze: specieData,
        transport_type: methodData,
        date_from: selectedDuringFrom,
        date_to: selectedDuringTo,
        region: regionData
      }),
    {
      keepPreviousData: true
    }
  )

  //Bar chart data
  const {
    data: barChartData,
    error: barChartError,
    isError: isBarChartError,
    isLoading: isBarChartLoading
  } = useQuery(
    [
      "getSeizureWeightTransportType",
      specieData,
      methodData,
      selectedDuringFrom,
      selectedDuringTo,
      regionData
    ],
    () =>
      getSeizureWeightTransportType({
        analyze: specieData,
        transport_type: methodData,
        date_from: selectedDuringFrom,
        date_to: selectedDuringTo,
        region: regionData
      }),
    {
      keepPreviousData: true
    }
  )

  //Bubble chart data
  const {
    data: bubbleChartData,
    error: bubbleChartError,
    isError: isBubbleChartError,
    isLoading: isBubbleChartLoading
  } = useQuery(
    [
      "getTotalWeightTransportType",
      specieData,
      methodData,
      selectedDuringFrom,
      selectedDuringTo,
      regionData
    ],
    () =>
      getTotalWeightTransportType({
        analyze: specieData,
        transport_type: methodData,
        date_from: selectedDuringFrom,
        date_to: selectedDuringTo,
        region: regionData
      }),
    {
      keepPreviousData: true
    }
  )

  //Average table data
  const {
    data: averageTableData,
    error: averageTableError,
    isError: isAverageTableError,
    isLoading: isAverageTableLoading
  } = useQuery(
    [
      "getAverageWeightTransportType",
      specieData,
      methodData,
      selectedDuringFrom,
      selectedDuringTo,
      regionData
    ],
    () =>
      getAverageWeightTransportType({
        analyze: specieData,
        transport_type: methodData,
        date_from: selectedDuringFrom,
        date_to: selectedDuringTo,
        region: regionData
      }),
    {
      keepPreviousData: true
    }
  )

  //React text
  const { data: reactTextData } = useQuery(
    [
      "getTextTransportType",
      specieData,
      methodData,
      selectedDuringFrom,
      selectedDuringTo,
      regionData
    ],
    () =>
      getTextTransportType({
        analyze: specieData,
        transport_type: methodData,
        year_from: selectedDuringFrom.split("/")[2],
        year_to: selectedDuringTo.split("/")[2],
        region: regionData
      }),
    {
      keepPreviousData: true
    }
  )

  const reactText1 = useMemo(() => {
    if (!reactTextData?.text1) return ""

    if (Math.round(Number(reactTextData.text1.percentage)) === 0) {
      return "no change"
    }

    let p = Math.round(Number(reactTextData.text1.percentage))

    if (p > 0) {
      p = Math.abs(p) + "%" + " increase"
    } else {
      p = Math.abs(p) + "%" + " decrease"
    }

    return "a " + p
  }, [reactTextData])

  const reactText2 = useMemo(() => {
    if (!reactTextData?.text2) return "0"
    return reactTextData.text2
  }, [reactTextData])

  useEffect(() => {
    trackPageView({
      documentTitle: window.location.pathname + window.location.search
    })
    setSideBarVisible(true)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let newVal = ""
    if (methodData === "") {
      newVal = "air, land, mail, and sea"
    } else {
      newVal = replaceAndText(methodData)
    }
    setReactTransportType(newVal)
  }, [methodData])

  useEffect(() => {
    let newVal = ""
    if (specieData === "") {
      newVal = "wildlife product"
    } else {
      newVal = replaceAndText(specieData)
    }
    setReactSpecy(newVal)

    let weightSpecieData = specieData
    weightSpecieData = weightSpecieData.replace("tiger", "")
    weightSpecieData = weightSpecieData.replace("leopard", "")
    weightSpecieData = weightSpecieData.replace("lion", "")
    weightSpecieData = weightSpecieData.replace(",,", ",")
    weightSpecieData = weightSpecieData.replace(",,", ",")
    if (weightSpecieData[weightSpecieData.length - 1] === ",") {
      weightSpecieData = weightSpecieData.slice(0, -1)
    }
    if (weightSpecieData[0] === ",") {
      weightSpecieData = weightSpecieData.substring(1, weightSpecieData.length)
    }

    if (specieData !== "") {
      if (weightSpecieData === "") {
        setIsWeightSpecy(false)
      } else {
        setIsWeightSpecy(true)
      }
    }
  }, [specieData])

  useEffect(() => {
    let text = ""
    const activeRegions = []
    const activeCountries = []
    Object.keys(selectedRegion).map(key => {
      if (regions.findIndex(region => region.key === key) !== -1) {
        if (selectedRegion[key].checked) {
          activeRegions.push(key)
        }
      } else {
        activeCountries.push(key)
      }
    })

    const filteredActiveCountries = []
    activeCountries.forEach(country => {
      const cc = countries.find(c => c.label === country)
      if (cc) {
        if (activeRegions.findIndex(region => region === cc.region) === -1) {
          filteredActiveCountries.push(country)
        }
      }
    })

    activeRegions.forEach(region => {
      text += region + ","
    })
    filteredActiveCountries.forEach(country => {
      text += country + ","
    })

    if (text === "") {
      text = " globally"
    } else {
      // remove last comma
      text = text.slice(0, -1)
      // add "and"
      text = replaceAndText(text)
      // add "linked to"
      text = " linked to " + text
    }

    setReactRegion(text)
  }, [regionData, selectedRegion])

  return (
    <SideContainer>
      <SLTitle>Wildlife Trafficking in Motion</SLTitle>
      <SLDesc>
        Licit transportation networks can be used for illicit means, including
        to facilitate wildlife trafficking. A multitude of transport sectors,
        such as air, land, mail, and sea, are vulnerable to exploitation by
        wildlife traffickers. Seizures associated with these four aforementioned
        transport types can be further defined as:
      </SLDesc>
      <SLTips>
        <div>
          <b>Air</b> – Seizures made at airports or affiliated cargo warehouses,
          encompassing both passenger-carried wildlife shipments and shipments
          via air freight services.
        </div>
        <div>
          <b>Land</b> – Seizures made at inland locations, outside of logistics
          hubs (like airports, maritime ports, and mail processing facilities);
          land seizures may take place in homes, in company warehouses, on
          roads, or at land border crossings.
        </div>
        <div>
          <b>Mail</b> – Seizures made at a postal and/or parcel processing
          facility or customs inspection point, encompassing both personal
          shipments of any size and commercial shipments via package delivery or
          courier service.
        </div>
        <div>
          <b>Sea</b> – Seizures made at a maritime port, an affiliated cargo
          warehouse, a customs inspection point, or from boats along various
          bodies of water, including seizures of shipments carried by vessel
          passengers or crewmembers and containerized shipments on cargo ships.
        </div>
      </SLTips>
      <Card
        title="Proportion of Seizures by Transport Type"
        desc=""
        legendType="transport"
        showDownload
      >
        {isPieChartLoading ? (
          <Loader />
        ) : isPieChartError ? (
          pieChartError
        ) : (
          <PieChart data={pieChartData} />
        )}
      </Card>
      <SLTitle className="mt-4">
        Number of Seizures vs. Number of Country-Linked Seizures
      </SLTitle>
      <SLDesc>
        A seizure count is the number of interdictions of wildlife or wildlife
        products in a given location, whereas a country-linked seizure count
        refers to the number of incidents of wildlife trafficking that
        originated, transited, or were destined for a specific country,
        regardless of whether or not the incident of wildlife trafficking was
        interdicted in that country. The county-linked seizure count of a
        country is therefore always equal to or greater than the seizure count
        of the same country.
        <br />
        <br />
        For example, if a shipment of rhino horn originating in South Africa and
        destined for Vietnam is seized in transit at an airport in Turkey,
        Turkey would have a count of 1 for seizures and 1 for country-linked
        seizures; South Africa would have a count of 0 for seizures and 1 for
        country-linked seizures, and Vietnam would have a count of 0 for
        seizures and 1 for country-linked seizure.
        <br />
        <br />
        Instances of a wildlife trafficking shipment passing through a country
        without being seized are considered “missed” seizure opportunities.
        Furthermore, in instances where a wildlife trafficking shipment is
        seized prior to reaching its intended destination, the destination
        country would be considered as having had “no opportunity” to seize the
        shipment. Therefore, in the prior example South Africa would be
        considered as having “missed” the seizure and Vietnam would be
        considered as having had “no opportunity” to interdict the shipment.
      </SLDesc>
      <SLReactText className="mt-4">
        {`Between ${reactTextData?.text1?.fromYear} and ${
          reactTextData?.text1?.toYear
        }, there was ${reactText1} in seizures of ${reactSpecy} ${
          reactTransportType
            ? "transported by " + reactTransportType + reactRegion + "."
            : ""
        }`}
      </SLReactText>
      <Card
        title={
          activeBarChartWeight
            ? "Weight of Seizures by Transport Type"
            : "Number of Seizures by Transport Type"
        }
        desc=""
        showDownload
        weightSeized={activeBarChartWeight}
      >
        {isBarChartLoading ? (
          <Loader />
        ) : isBarChartError ? (
          barChartError
        ) : (
          <BarChartHolder>
            <LetterToggle onChange={handleBarChartToggleChange} />
            <BarChartList>
              {stackData.map((item, index) => (
                <div key={index} className="col-md-6 my-3">
                  <TransportStackChart
                    label={item.label}
                    data={barChartData?.[item.value]}
                    activeWeight={activeBarChartWeight}
                  />
                </div>
              ))}
            </BarChartList>
          </BarChartHolder>
        )}
      </Card>
      <SLTitle className="mt-4">Transport Type and Weight</SLTitle>
      <SLDesc>
        The weight of a wildlife shipment can also be a factor in determining
        which transport type is utilized by traffickers. For instance, large
        quantities of wildlife product may be more effectively conveyed in
        shipping containers as sea cargo, as opposed to being moved piecemeal in
        passenger luggage on commercial airlines. However, the larger the weight
        of a shipment, the more complex are the logistics and the higher the
        loss for trafficking networks if the shipment is seized.
      </SLDesc>
      <SLReactText>
        {`${reactTransportType} seizures accounted for ${Number(
          reactText2?.seizureCountPercentage
        ).toFixed(1)}% of ${reactSpecy} seizures ${reactRegion} between ${
          reactTextData?.text2?.fromYear
        } and ${reactTextData?.text2?.toYear}${
          isWeightSpecy
            ? `, but ${Number(reactText2?.seizureWeightPercentage).toFixed(
                1
              )}% of product weight seized.`
            : "."
        }`}
      </SLReactText>
      <Card
        title="Total Weight Seized by Transport Type"
        desc=""
        legendType="transport"
        // weightSeized
        showDownload
      >
        <ChartDesc>
          The graph below displays the total weight of wildlife seizures by four
          common transportation types: air, sea, land, and mail. The size of the
          bubble around these points indicates the average weight of wildlife
          seizures by that transport method. To adjust the time period covered
          by this graph, select a start and end date in the “date” filter.
        </ChartDesc>
        {isBubbleChartLoading ? (
          <Loader />
        ) : isBubbleChartError ? (
          bubbleChartError
        ) : (
          <BubbleChartHolder>
            <TransportTypeScatterChart data={bubbleChartData} />
          </BubbleChartHolder>
        )}
      </Card>
      <Card
        className="mt-4"
        title="Average Weight Seized by Transport Type"
        desc=""
        showSpecies={false}
        showDownload={false}
      >
        <ChartDesc>
          In contrast to the graph above, which shows the total weight of
          wildlife seizures by transport type, the table below delineates the
          average weight of wildlife seizures by both species and transport
          type.
        </ChartDesc>
        {isAverageTableLoading ? (
          <Loader />
        ) : isAverageTableError ? (
          averageTableError
        ) : (
          <AverageTable data={averageTableData} />
        )}
      </Card>
    </SideContainer>
  )
}

const PieChart = styled(TransportTypePieChart)`
  width: 100%;
`
const BarChartHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 1em;
`

const BarChartList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  div {
    flex-direction: column;
  }
`

const BubbleChartHolder = styled.div`
  padding: 1em;
`
