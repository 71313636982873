import { useEffect, useLayoutEffect, useState } from "react"
import styled from "styled-components"
import pako from "pako"
import Sticky from "wil-react-sticky"
import Clipboard from "react-clipboard.js"
import { getUrlParams } from "utils"
import { MultiSelect } from "primereact/multiselect"
import { TreeSelect } from "primereact/treeselect"
import { Dropdown } from "primereact/dropdown"
import { medias, species, regions } from "dataset/filters"
import { EndDateItems, StartDateItems } from "dataset/sidebar"
import { useStore } from "state/store"
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos
} from "react-icons/md"
import OutlineButton from "components/OutlineButton"

import ShareIcon from "assets/images/icon-share.svg"
import CheckIcon from "assets/images/icon-check.png"
import { useMedia } from "react-media"
import { device } from "theme/device"

export default function Sidebar() {
  const sideBarVisible = useStore(state => state.sideBarVisible)

  const sideBarCollapsed = useStore(state => state.sideBarCollapsed)
  const setSideBarCollapsed = useStore(state => state.setSideBarCollapsed)

  const handleVisible = () => {
    setSideBarCollapsed(!sideBarCollapsed)
  }

  const isPadScreen = useMedia({ query: device.pad })

  useLayoutEffect(() => {
    setSideBarCollapsed(isPadScreen)
  }, [isPadScreen])

  return sideBarVisible ? (
    <SidebarHolder visible={!sideBarCollapsed}>
      <ToggleButton collapsed={!sideBarCollapsed} onClick={handleVisible}>
        {!sideBarCollapsed ? (
          <MdOutlineArrowBackIosNew color="#000" />
        ) : (
          <MdOutlineArrowForwardIos color="#000" />
        )}
      </ToggleButton>
      <Sticky containerSelectorFocus="#stickySelectorFocus" offsetTop={120}>
        <SidebarContent />
      </Sticky>
    </SidebarHolder>
  ) : (
    <></>
  )
}

const SidebarContent = () => {
  const selectedSpecie = useStore(state => state.selectedSpecie)
  const setSelectedSpecie = useStore(state => state.setSelectedSpecie)
  const selectedMethod = useStore(state => state.selectedMethod)
  const setSelectedMethod = useStore(state => state.setSelectedMethod)
  const selectedRegion = useStore(state => state.selectedRegion)
  const setSelectedRegion = useStore(state => state.setSelectedRegion)
  const selectedDuringFrom = useStore(state => state.selectedDuringFrom)
  const setSelectedDuringFrom = useStore(state => state.setSelectedDuringFrom)
  const selectedDuringTo = useStore(state => state.selectedDuringTo)
  const setSelectedDuringTo = useStore(state => state.setSelectedDuringTo)
  const resetFilters = useStore(state => state.resetFilters)

  const [shareBtnText, setShareBtnText] = useState("Share filtered result")
  const [endDateItems, setEndDateItems] = useState(EndDateItems)

  const handleReset = () => {
    resetFilters()
  }

  const generateLink = () => {
    let params = {
      selectedSpecie,
      selectedMethod,
      selectedRegion,
      selectedDuringFrom,
      selectedDuringTo
    }
    const binaryString = pako.deflate(JSON.stringify(params), { to: "string" })
    const base64Encoded = btoa(binaryString)
    return (
      window.location.origin +
      window.location.pathname +
      "?share=" +
      base64Encoded
    )
  }

  const onCopySuccess = () => {
    // console.log("text copied...")
    setShareBtnText("Copied to clipboard")
    setTimeout(() => {
      setShareBtnText("Share filtered result")
    }, 800)
  }
  const decodeSharedLink = async compressed => {
    const base64Decoded = atob(compressed)
    let original = pako.inflate(base64Decoded, { to: "string" })
    const params2 = JSON.parse(original)

    const {
      selectedSpecie,
      selectedMethod,
      selectedRegion,
      selectedDuringFrom,
      selectedDuringTo
    } = params2
    setSelectedSpecie(selectedSpecie)
    setSelectedMethod(selectedMethod)
    setSelectedRegion(selectedRegion)
    setSelectedDuringFrom(selectedDuringFrom)
    setSelectedDuringTo(selectedDuringTo)
  }

  useEffect(() => {
    const { share = "" } = getUrlParams()
    if (share) {
      decodeSharedLink(share)
    }

    window.onscroll = function () {
      const mPanels = document.getElementsByClassName("p-multiselect-panel")
      if (mPanels.length > 0) {
        mPanels[0].classList.add("p-panel-close")
      }

      const tPanels = document.getElementsByClassName("p-treeselect-panel")
      if (tPanels.length > 0) {
        tPanels[0].classList.add("p-panel-close")
      }

      const dPanels = document.getElementsByClassName("p-dropdown-panel")
      if (dPanels.length > 0) {
        dPanels[0].classList.add("p-panel-close")
      }
    }
  }, [])

  return (
    <List>
      <Label>Category</Label>
      <Select
        placeholder="Please select"
        value={selectedSpecie}
        options={species}
        showSelectAll={false}
        onChange={e => setSelectedSpecie(e.value)}
        // tooltip="The “pangolin” category includes both pangolin scales and whole (live/dead)   pangolins unless otherwise specified."
      />
      <Label className="mt-4">Trafficked via</Label>
      <Select
        placeholder="Please select"
        value={selectedMethod}
        options={medias}
        showSelectAll={false}
        onChange={e => setSelectedMethod(e.value)}
      />
      <Label>During</Label>
      <YearRanger>
        <Dropdown
          value={selectedDuringFrom}
          options={StartDateItems}
          onChange={e => {
            setSelectedDuringFrom(e.value)

            //Update end date options
            const startIndex = EndDateItems.findIndex(
              item => item.label === e.value.split("/")[2]
            )
            const newEndDateItems = []
            if (startIndex >= 0) {
              for (let i = startIndex; i < EndDateItems.length; i++) {
                newEndDateItems.push(EndDateItems[i])
              }
            }
            setEndDateItems(newEndDateItems)

            //Update end date
            const idx = newEndDateItems.findIndex(
              item => item.value === selectedDuringTo
            )
            if (idx === -1) {
              setSelectedDuringTo(newEndDateItems[0].value)
            }
          }}
          placeholder="From"
        />
        <Dropdown
          value={selectedDuringTo}
          options={endDateItems}
          onChange={e => {
            setSelectedDuringTo(e.value)
          }}
          placeholder="To"
        />
      </YearRanger>
      <Label>Location</Label>
      <RegionSelect
        placeholder={"Please select"}
        value={selectedRegion}
        options={regions}
        filter={true}
        onChange={e => {
          setSelectedRegion(e.value)
        }}
        selectionMode="checkbox"
      />
      <BottomHolder>
        <Clipboard
          component="a"
          button-href="#"
          onClick={e => e.preventDefault()}
          className="mt-4"
          data-clipboard-text={generateLink()}
          onSuccess={onCopySuccess}
          button-title="Share this page"
        >
          <OutlineButton
            className="w-100"
            icon={
              shareBtnText === "Copied to clipboard" ? CheckIcon : ShareIcon
            }
          >
            {shareBtnText}
          </OutlineButton>
        </Clipboard>
        <ResetButton className="mt-4 mx-auto" onClick={handleReset}>
          Reset all filters
        </ResetButton>
      </BottomHolder>
    </List>
  )
}

const SidebarHolder = styled.aside`
  position: relative;
  width: ${({ visible }) => (visible ? "384px" : "0")};
  min-width: ${({ visible }) => (visible ? "384px" : "0")};
  padding: ${({ visible }) => (visible ? "50px 60px" : "0")};
  gap: 1em;
  background-color: #f3f9fd;
  transition: all 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  margin: 0;
`

const List = styled.div`
  width: 100%;
  overflow: hidden;
  transition: all 0.2s ease-out;
`

const Label = styled.div`
  font-weight: bold;
  font-size: 1em;
  line-height: 22px;
  color: #043d5d;
  margin-top: 1em;
  margin-bottom: 8px;
`

const YearRanger = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  width: 100%;
  div {
    flex: 1;
  }
`

const Select = styled(MultiSelect)`
  width: 100%;
`
const RegionSelect = styled(TreeSelect)`
  width: 100%;
`
const ToggleButton = styled.button`
  width: 40px;
  height: 40px;
  outline: unset;
  border: unset;
  position: absolute;
  top: 0;
  right: ${props => (props.collapsed ? "40px" : "0px")};
  z-index: 1;
  transform: translateX(100%);
  cursor: pointer;
  background: #d7e7f1;
  transition: all 400ms ease;
  display: flex;
  svg {
    margin: auto;
  }
`

const BottomHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const ResetButton = styled.div`
  color: #7b8694;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
  transition: all 300ms ease;
  width: fit-content;
  &:hover {
    color: #e2e2e2;
  }
`
