import React from "react"
import styled from "styled-components"
import LoadingIcon from "assets/images/icon-spinner.gif"

export default function OutlineButton({
  className,
  width,
  onClick = () => {},
  children,
  icon,
  isWaiting = false,
  disabled = false
}) {
  return (
    <Container
      className={className}
      width={width}
      onClick={disabled ? () => {} : onClick}
      disable={disabled}
    >
      {icon && <img src={icon} alt="" />}
      <span>{children}</span>
      {isWaiting && <LoadingImg src={LoadingIcon} />}
    </Container>
  )
}

const Container = styled.button`
  background: white;
  height: 48px;
  color: ${props => (props.disable ? "#ccc" : "#479577")};
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  width: ${props => (props.width ? `${props.width}px` : "auto")};
  border: none;
  outline: none;
  transition: all 300ms ease;
  border: ${props => (props.disable ? "2px solid #ccc" : "2px solid #479577")};
  &:hover {
    border: 2px solid #174c66;
  }
  img {
    width: 20px;
    margin-right: 10px;
  }
`

const LoadingImg = styled.img`
  margin-left: 10px;
  width: 34px;
`
