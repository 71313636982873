import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ScatterChart,
  ZAxis,
  Scatter
} from "recharts"
import { addCommaToNumber } from "utils"
import { ChartTooltipHolder } from "./ChartElements"

export default function TransportTypeScatterChart({ data = [] }) {
  return (
    <ResponsiveContainer width="100%" height={320}>
      <ScatterChart
        width={400}
        height={400}
        margin={{
          top: 20,
          right: 40,
          bottom: 20,
          left: 20
        }}
      >
        <Tooltip
          cursor={{ strokeDasharray: "3 3" }}
          content={<CustomTooltip />}
        />
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="x"
          type="number"
          name="Weight"
          label={{
            value: "Weight of Seizures (kg)",
            position: "insideBottom",
            offset: -15,
            fontSize: "0.9em",
            fill: "#939393"
          }}
          unit="kg"
        />
        <YAxis
          dataKey="y"
          type="number"
          name="No. of seizures"
          label={{
            value: "Number of Seizures",
            angle: -90,
            position: "insideLeft",
            dy: 50,
            dx: -5,
            fontSize: "0.9em",
            fill: "#939393"
          }}
        />
        <ZAxis
          dataKey="z"
          type="number"
          name="Average weight"
          unit="kg"
          range={[300, 2000]}
        />
        {data.map((item, index) => (
          <Scatter
            key={index}
            data={item.data}
            name={item.label}
            fill={item.color}
            shape="circle"
          />
        ))}
      </ScatterChart>
    </ResponsiveContainer>
  )
}

const CustomTooltip = ({ active, payload }) => {
  if (!active) return null

  for (let i = 0; i < payload.length; i++) {
    const data = payload[i].payload
    return (
      <ChartTooltipHolder>
        <h5>{data.label}</h5>
        <h6>{`Weight: ${addCommaToNumber(data.x)} kg`}</h6>
        <h6>{`No. of seizures: ${addCommaToNumber(data.y)}`}</h6>
        <h6>{`Average weight: ${addCommaToNumber(data.z)} kg`}</h6>
      </ChartTooltipHolder>
    )
  }

  return null
}
