import { EndDateItems, StartDateItems } from "dataset/sidebar"
import create from "zustand"

export const SideBar_Init_Value = {
  selectedSpecie: [],
  selectedMethod: [],
  selectedRegion: {},
  selectedDuringFrom: StartDateItems[0].value,
  selectedDuringTo: EndDateItems[EndDateItems.length - 1].value
}

export const useStore = create((set, get) => ({
  //Menu
  menuVisible: false,
  setMenuVisible: v => set({ menuVisible: v }),
  //Header
  headerVisible: true,
  setHeaderVisible: v => set({ headerVisible: v }),
  //SideBar
  sideBarVisible: true,
  setSideBarVisible: v => set({ sideBarVisible: v }),
  sideBarCollapsed: false,
  setSideBarCollapsed: v => set({ sideBarCollapsed: v }),
  //Filters
  selectedSpecie: SideBar_Init_Value.selectedSpecie,
  setSelectedSpecie: v => set({ selectedSpecie: v }),
  selectedMethod: SideBar_Init_Value.selectedMethod,
  setSelectedMethod: v => set({ selectedMethod: v }),
  selectedRegion: SideBar_Init_Value.selectedRegion,
  setSelectedRegion: v => set({ selectedRegion: v }),
  selectedDuringFrom: SideBar_Init_Value.selectedDuringFrom,
  setSelectedDuringFrom: v => set({ selectedDuringFrom: v }),
  selectedDuringTo: SideBar_Init_Value.selectedDuringTo,
  setSelectedDuringTo: v => set({ selectedDuringTo: v }),
  resetFilters: () => {
    get().setSelectedSpecie(SideBar_Init_Value.selectedSpecie)
    get().setSelectedMethod(SideBar_Init_Value.selectedMethod)
    get().setSelectedRegion(SideBar_Init_Value.selectedRegion)
    get().setSelectedDuringFrom(SideBar_Init_Value.selectedDuringFrom)
    get().setSelectedDuringTo(SideBar_Init_Value.selectedDuringTo)
  },
  //Globe
  isGlobe: true,
  setIsGlobe: v => set({ isGlobe: v }),
  profileGlobe: null,
  setProfileGlobe: v => set({ profileGlobe: v }),
  //Profile dialog
  openProfileDialog: false,
  setOpenProfileDialog: v => set({ openProfileDialog: v }),
  selectedCountryName: "",
  setSelectedCountryName: v => set({ selectedCountryName: v })
}))
