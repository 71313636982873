import { useEffect, useMemo, useState } from "react"
import { SideContainer } from "components/Containers"
import { useStore } from "state/store"
import { useQuery } from "react-query"
import { useMatomo } from "@datapunt/matomo-tracker-react"
import ReactTooltip from "react-tooltip"
import {
  getTraffickingOverTIme,
  getTextOverTime,
  getTraffickingOverTImeBubble
} from "api"
import Loader from "components/Loader"
import Card from "components/Card"
import styled from "styled-components"
import TraffickingTimeChart from "components/Charts/TraffickingTimeChart"
import OverTimeLineChart from "components/Charts/OverTimeLineChart"
import OverTimeScatterChart from "components/Charts/OverTimeScatterChart"
import { ChartDesc, SLReactText, SLTitle } from "components/SeveralLetters"
import {
  convert2Region,
  convert2Method,
  convert2Specie
} from "utils/convertDataFormat"
import { replaceAndText } from "utils"
import { SpecyLegendData, SpecyWeightLegendData } from "dataset/legend"
import LetterToggle from "components/LetterToggle"
import { countries, regions } from "dataset/filters"
import InfoIcon from "assets/images/icon-info.svg"

export default function OverTime() {
  const { trackPageView } = useMatomo()
  const setSideBarVisible = useStore(state => state.setSideBarVisible)

  const selectedSpecie = useStore(state => state.selectedSpecie)
  const selectedMethod = useStore(state => state.selectedMethod)
  const selectedRegion = useStore(state => state.selectedRegion)
  const selectedDuringFrom = useStore(state => state.selectedDuringFrom)
  const selectedDuringTo = useStore(state => state.selectedDuringTo)

  const specieData = convert2Specie(selectedSpecie)
  const methodData = convert2Method(selectedMethod)
  const regionData = convert2Region(selectedRegion)

  const [reactTransportType, setReactTransportType] = useState("")
  const [reactSpecy, setReactSpecy] = useState("wildlife product")
  const [reactRegion, setReactRegion] = useState("worldwide")
  const [reactKilo, setReactKilo] = useState("")
  const [reactWeightSpecy, setReactWeightSpecy] = useState("")

  const {
    data: chartData,
    error,
    isError,
    isLoading
  } = useQuery(
    [
      "getTraffickingOverTIme",
      specieData,
      methodData,
      selectedDuringFrom,
      selectedDuringTo,
      regionData
    ],
    () =>
      getTraffickingOverTIme({
        analyze: specieData,
        transport_type: methodData,
        date_from: selectedDuringFrom,
        date_to: selectedDuringTo,
        region: regionData
      }),
    {
      keepPreviousData: true
    }
  )

  const filteredChartData = useMemo(() => {
    if (specieData === "") {
      return chartData
    }

    const result = []
    const species = specieData.split(",")

    const specyLegendData = []
    SpecyLegendData.map(item => {
      if (species.findIndex(s => s === item.name) >= 0) {
        specyLegendData.push(item)
      }
    })
    SpecyWeightLegendData.map(item => {
      if (species.findIndex(s => s === item.name) >= 0) {
        specyLegendData.push(item)
      }
    })

    chartData?.map(item => {
      const d = {}
      Object.keys(item).map(key => {
        if (
          specyLegendData.findIndex(s => s.label === key) >= 0 ||
          key === "year" ||
          key === "overall"
        ) {
          d[key] = item[key]
        }
      })
      result.push(d)
    })

    return result
  }, [chartData])

  const {
    data: bubbleChartData,
    error: bubbleChartError,
    isError: bubbleChartIsError,
    isLoading: bubbleChartIsLoading
  } = useQuery(
    [
      "getTraffickingOverTImeBubble",
      specieData,
      methodData,
      selectedDuringFrom,
      selectedDuringTo,
      regionData
    ],
    () =>
      getTraffickingOverTImeBubble({
        analyze: specieData,
        transport_type: methodData,
        date_from: selectedDuringFrom,
        date_to: selectedDuringTo,
        region: regionData
      }),
    {
      keepPreviousData: true
    }
  )

  const formattedBubbleChartData = useMemo(() => {
    const result = []

    let ivoryCount = 0
    let pangolinCount = 0
    let rhinoCount = 0

    let ivoryWeight = 0
    let pangolinWeight = 0
    let rhinoWeight = 0

    let ivoryAmount = 0
    let pangolinAmount = 0
    let rhinoAmount = 0

    bubbleChartData?.map(item => {
      ivoryCount += Number(item.ivory_count)
      pangolinCount += Number(item.pangolin_count)
      rhinoCount += Number(item.rhino_count)

      ivoryWeight += Number(item.ivory_weight)
      pangolinWeight += Number(item.pangolin_weight)
      rhinoWeight += Number(item.rhino_weight)

      ivoryAmount += Number(item.ivory_amount)
      pangolinAmount += Number(item.pangolin_amount)
      rhinoAmount += Number(item.rhino_amount)
    })

    let selectedSpecies =
      specieData === "" ? "ivory,pangolin,rhino" : specieData

    if (selectedSpecies.includes("ivory")) {
      result.push({
        data: [
          {
            x: Math.round(ivoryWeight),
            y: ivoryCount,
            z: Math.round(ivoryWeight / ivoryAmount),
            label: "Ivory"
          }
        ],
        label: "Ivory",
        color: "#31617C"
      })
    }

    if (selectedSpecies.includes("pangolin")) {
      result.push({
        data: [
          {
            x: Math.round(pangolinWeight),
            y: pangolinCount,
            z: Math.round(pangolinWeight / pangolinAmount),
            label: "Pangolin Scales"
          }
        ],
        label: "Pangolin Scales",
        color: "#F27676"
      })
    }

    if (selectedSpecies.includes("rhino")) {
      result.push({
        data: [
          {
            x: Math.round(rhinoWeight),
            y: rhinoCount,
            z: Math.round(rhinoWeight / rhinoAmount),
            label: "Rhino Horn"
          }
        ],
        label: "Rhino Horn",
        color: "#73A98F"
      })
    }

    return result
  }, [bubbleChartData])

  //React text
  const { data: reactTextData } = useQuery(
    [
      "getTextOverTime",
      specieData,
      methodData,
      selectedDuringFrom,
      selectedDuringTo,
      regionData
    ],
    () =>
      getTextOverTime({
        analyze: specieData,
        transport_type: methodData,
        year_from: selectedDuringFrom.split("/")[2],
        year_to: selectedDuringTo.split("/")[2],
        region: regionData
      }),
    {
      keepPreviousData: true
    }
  )

  const percentReactText1 = useMemo(() => {
    if (!reactTextData) return ""

    if (Math.round(Number(reactTextData.percentage)) === 0) {
      return "no change"
    }

    let p = Math.round(Number(reactTextData.percentage))

    if (p > 0) {
      p = Math.abs(p) + "%" + " increase"
    } else {
      p = Math.abs(p) + "%" + " decrease"
    }

    return p
  }, [reactTextData])

  const percent1ReactText2 = useMemo(() => {
    if (!reactTextData) return ""

    if (Math.round(Number(reactTextData.percentAvgWeight)) === 0) {
      return "no change"
    }

    let p = Math.round(Number(reactTextData.percentAvgWeight))

    if (p > 0) {
      p = "increased by " + Math.abs(p) + "%"
    } else {
      p = "decreased by " + Math.abs(p) + "%"
    }

    return p
  }, [reactTextData])

  const percent2ReactText2 = useMemo(() => {
    if (!reactTextData) return "0"

    let p = reactTextData.text2.onlyRhino
      ? Number(reactTextData.text2.aboveTenKgWeightpercentage).toFixed(1)
      : Number(reactTextData.text2.aboveHundredKgWeightpercentage).toFixed(1)

    return p
  }, [reactTextData])

  useEffect(() => {
    trackPageView({
      documentTitle: window.location.pathname + window.location.search
    })
    setSideBarVisible(true)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let newVal = ""
    if (methodData === "") {
      newVal = "air, land, mail, and sea"
    } else {
      newVal = replaceAndText(methodData)
    }
    setReactTransportType(newVal)
  }, [methodData])

  useEffect(() => {
    ////
    let newVal = ""
    if (specieData === "") {
      newVal = "wildlife product"
    } else {
      newVal = replaceAndText(specieData)
    }
    setReactSpecy(newVal)

    ////
    let newVal1 = ""
    let weightSpecieData = specieData
    weightSpecieData = weightSpecieData.replace("tiger", "")
    weightSpecieData = weightSpecieData.replace("leopard", "")
    weightSpecieData = weightSpecieData.replace("lion", "")
    weightSpecieData = weightSpecieData.replace(",,", ",")
    weightSpecieData = weightSpecieData.replace(",,", ",")
    if (weightSpecieData[weightSpecieData.length - 1] === ",") {
      weightSpecieData = weightSpecieData.slice(0, -1)
    }
    if (weightSpecieData[0] === ",") {
      weightSpecieData = weightSpecieData.substring(1, weightSpecieData.length)
    }
    console.log(weightSpecieData)
    if (weightSpecieData === "") {
      newVal1 = "wildlife product"
    } else {
      newVal1 = replaceAndText(weightSpecieData)
    }
    setReactWeightSpecy(newVal1)

    //Kilo
    let newVal2 = "100kg"
    if (specieData === "rhino") {
      newVal2 = "10kg"
    }
    setReactKilo(newVal2)
  }, [specieData])

  useEffect(() => {
    let text = ""
    const activeRegions = []
    const activeCountries = []
    Object.keys(selectedRegion).map(key => {
      if (regions.findIndex(region => region.key === key) !== -1) {
        if (selectedRegion[key].checked) {
          activeRegions.push(key)
        }
      } else {
        activeCountries.push(key)
      }
    })

    const filteredActiveCountries = []
    activeCountries.forEach(country => {
      const cc = countries.find(c => c.label === country)
      if (cc) {
        if (activeRegions.findIndex(region => region === cc.region) === -1) {
          filteredActiveCountries.push(country)
        }
      }
    })

    activeRegions.forEach(region => {
      text += region + ","
    })
    filteredActiveCountries.forEach(country => {
      text += country + ","
    })

    if (text === "") {
      text = "worldwide"
    } else {
      // remove last comma
      text = text.slice(0, -1)
    }

    text = replaceAndText(text)

    setReactRegion(text)
  }, [regionData, selectedRegion])

  //Line chart weight
  const [activeLineChartWeight, setActiveLineChartWeight] = useState(0)
  const handleLineChartToggleChange = v => {
    setActiveLineChartWeight(v)
  }

  //Bar chart weight
  const [activeBarChartWeight, setActiveBarChartWeight] = useState(0)
  const handleBarChartToggleChange = v => {
    setActiveBarChartWeight(v)
  }

  return (
    <SideContainer>
      <TitleHolder>
        <SLTitle>Change Over Time</SLTitle>
        <a data-tip data-for="ChangeOverTime-Tooltip">
          <InfoImg src={InfoIcon} />
        </a>
      </TitleHolder>
      <SLReactText>
        {`Between ${reactTextData?.fromYear} and ${reactTextData?.toYear}${
          regionData ? " in " + reactRegion : ""
        }, ${
          percentReactText1 === "no change"
            ? `there is not enough data to determine if there was an increase or decrease`
            : `there was a ${percentReactText1}`
        } in seizures of ${reactSpecy} ${
          reactTransportType ? "transported by " + reactTransportType + "." : ""
        }`}
      </SLReactText>
      <Card
        title={
          activeLineChartWeight
            ? "Weight of Seizures Over Time"
            : "Number of Seizures Over Time"
        }
        desc=""
        showDownload
        weightSeized={activeLineChartWeight}
      >
        {isLoading ? (
          <Loader />
        ) : isError ? (
          error
        ) : (
          <ChartWrapper>
            <LetterToggle onChange={handleLineChartToggleChange} />
            <OverTimeLineChart
              data={filteredChartData}
              activeWeight={activeLineChartWeight}
            />
          </ChartWrapper>
        )}
      </Card>
      <SLReactText className="mt-4">
        It is important to note that a change in seizures is not necessarily
        indicative of an increase or decrease in wildlife trafficking, but could
        be the result of a change in detection capacity, reporting, or other
        factors.
      </SLReactText>
      <Card
        title={
          activeBarChartWeight
            ? "Weight of Seizures Over Time"
            : "Number of Seizures Over Time"
        }
        desc=""
        showDownload
        weightSeized={activeBarChartWeight}
      >
        {isLoading ? (
          <Loader />
        ) : isError ? (
          error
        ) : (
          <ChartWrapper>
            <LetterToggle onChange={handleBarChartToggleChange} />
            <TraffickingTimeChart
              data={filteredChartData}
              activeWeight={activeBarChartWeight}
            />
          </ChartWrapper>
        )}
      </Card>
      <SLReactText className="mt-4">
        {percent1ReactText2 === "no change"
          ? `There is not enough data to determine the average weight of ${reactSpecy} seizures transported by ${reactTransportType} between ${reactTextData?.fromYear} and ${reactTextData?.toYear}.`
          : `The average weight of ${reactWeightSpecy} seizures transported by ${reactTransportType} between ${
              reactTextData?.fromYear
            } and ${reactTextData?.toYear} ${
              regionData ? " in " + reactRegion : ""
            } ${percent1ReactText2}. Furthermore, ${percent2ReactText2}% of all ${reactWeightSpecy} seizures with recorded weights that were ${
              reactTransportType ? "transported by " + reactTransportType : ""
            } between ${reactTextData?.fromYear} and ${
              reactTextData?.toYear
            } were of quantities larger than ${reactKilo}.`}
      </SLReactText>
      <Card
        title="Weight Seized by Number of Seizures"
        desc=""
        weightSeized
        showDownload
      >
        <ChartDesc>
          The graph below displays the total weight of wildlife seizures by
          species category over the years. To adjust the time period covered by
          this graph, select a start and end date in the filter. The size of the
          bubble on the graph is indicative of the average weight per seizure –
          the larger the bubble, the larger the average weight in kg. Seizure
          weight is only available for ivory, rhino horn, and pangolin, since
          seizures of leopard, lion, and tiger are not commonly recorded with a
          weight.
        </ChartDesc>
        {bubbleChartIsLoading ? (
          <Loader />
        ) : bubbleChartIsError ? (
          bubbleChartError
        ) : (
          <ChartWrapper>
            <OverTimeScatterChart data={formattedBubbleChartData} />
          </ChartWrapper>
        )}
      </Card>
      <ReactTooltip
        id="ChangeOverTime-Tooltip"
        type="warning"
        effect="solid"
        backgroundColor="#061830"
      >
        <span>
          If multiple categories of wildlife were intercepted in the same
          seizure, the incident will be represented in the graphics as a single
          seizure for each category of wildlife seized. For example, if ivory
          and rhino horn were seized in the same shipment, it will appear as one
          ivory seizure and one rhino horn seizure in their respective lines.
          Please refer to "global seizure count" to get the total number of
          seizures where multi-species seizures are only counted as one seizure.
        </span>
      </ReactTooltip>
    </SideContainer>
  )
}

const ChartWrapper = styled.div`
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const InfoImg = styled.img`
  width: 1.5em;
  height: 1.5em;
`
const TitleHolder = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  p {
    margin: 0;
  }
`
