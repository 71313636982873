import React from "react"
import styled from "styled-components"

export default function Button({
  className,
  width,
  onClick = () => {},
  children
}) {
  return (
    <Container className={className} width={width} onClick={onClick}>
      {children}
    </Container>
  )
}

const Container = styled.button`
  background: #479577;
  height: 48px;
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  width: ${props => (props.width ? `${props.width}px` : "auto")};
  border: none;
  outline: none;
  transition: all 300ms ease;
  border: 2px solid #479577;
  &:hover {
    border: 2px solid #174c66;
  }
`
