import styled from "styled-components"
import { device } from "theme/device"
import LogoIcon from "assets/images/logo.svg"
import { AboutRoute } from "dataset/routes"

export default function Footer() {
  return (
    <FooterHolder>
      <LogoPart>
        <Logo />
        <LogoLabel>
          C4ADS is a nonprofit think tank dedicated to providing data-driven
          analysis and evidence-based reporting on global conflict and
          transnational security issues.
        </LogoLabel>
      </LogoPart>
      <LinkPart>
        <ContactPart>
          <LinkItem
            target="_parent"
            href={AboutRoute.path}
            rel="noopener noreferrer"
          >
            About
          </LinkItem>
        </ContactPart>
        <ContactPart>
          <LinkItem
            target="_blank"
            href="mailto:wildlife@c4ads.org"
            rel="noopener noreferrer"
          >
            Contact Us
          </LinkItem>
        </ContactPart>
        <ContactPart>
          <LinkItem
            target="_blank"
            href="https://c4ads.org/privacy-policy"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </LinkItem>
        </ContactPart>
      </LinkPart>
    </FooterHolder>
  )
}

const FooterHolder = styled.footer`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1em;
  flex-wrap: wrap;
  margin: 0;
  padding: 24px 40px;
  @media ${device.mobileL} {
    padding: 1em;
  }
`
const LogoPart = styled.div`
  flex-grow: 1;
`

const Logo = styled.div`
  width: 8em;
  height: 2.8em;
  background-image: url(${LogoIcon});
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 11em;
    height: 2px;
    background-color: #479577;
  }
`
const LogoLabel = styled.div`
  padding-top: 0.5em;
  font-size: 0.9em;
  max-width: 40em;
`

const LinkPart = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const ContactPart = styled.div`
  padding-top: 2.5em;
  font-size: 0.9em;
  min-width: 14em;
  max-width: 20em;
  @media ${device.mobileL} {
    padding-top: 0.5em;
  }
`
const LinkItem = styled.a`
  font-size: 1em;
  line-height: 250%;
  font-weight: 700;
  color: #7b8694;
  transition: all 300ms ease;
  &:hover {
    text-decoration: underline;
    color: #479577;
  }
`
