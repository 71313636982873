import { useEffect, useMemo } from "react"
import { useQuery } from "react-query"
import styled from "styled-components"
import { useMatomo } from "@datapunt/matomo-tracker-react"
import { useStore } from "state/store"
import { getCountryMap } from "api"
import { device } from "theme/device"

import Globe from "components/Globe"
import Loader from "components/Loader"
import { SideContainer } from "components/Containers"
import ProfileDialog from "components/ProfileDialog"

import mapData from "dataset/states-list-with-continents.json"
import CountryNameMatches from "dataset/country_name_matches.json"

export default function CountryProfile() {
  const { trackPageView } = useMatomo()
  const setSideBarVisible = useStore(state => state.setSideBarVisible)
  const setIsGlobe = useStore(state => state.setIsGlobe)

  const {
    data: speciesMap,
    error,
    isError,
    isLoading
  } = useQuery(["getCountryMap"], () => getCountryMap(), {
    keepPreviousData: true
  })

  const globeData = useMemo(() => {
    const newData = []
    if (speciesMap) {
      mapData.forEach(feature => {
        const idx = speciesMap.findIndex(c => c.cads_name === feature.state)
        if (idx >= 0) {
          newData.push(speciesMap[idx])
        } else {
          const countryCode = CountryNameMatches.find(
            country => country.c4ads === feature.state
          )?.code
          const countryName = CountryNameMatches.find(
            country => country.c4ads === feature.state
          )?.amchart4

          if (countryCode && countryName) {
            const idxx = newData.findIndex(a => a.id === countryCode)
            if (idxx === -1) {
              newData.push({
                id: countryCode,
                name: countryName,
                cads_name: feature.state,
                value: 0,
                realvalue: 0
              })
            }
          }
        }
      })
    }
    return newData
  }, [speciesMap])

  useEffect(() => {
    trackPageView({
      documentTitle: window.location.pathname + window.location.search
    })
    setSideBarVisible(false)
    setIsGlobe(true)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Holder>
        <ChartHolder>
          {isLoading ? (
            <Loader />
          ) : isError ? (
            error
          ) : (
            <Globe data={globeData} />
          )}
        </ChartHolder>
        <Banner>Click a country to view seizure details</Banner>
      </Holder>
      <ProfileDialog />
    </>
  )
}

const Holder = styled(SideContainer)`
  padding: 0;
`

const ChartHolder = styled.div`
  height: 70vh;
  @media ${device.pad} {
    width: 95%;
  }
`

const Banner = styled.p`
  position: absolute;
  bottom: 3em;
  left: 50%;
  transform: translateX(-50%);
  background-color: #1b524e;
  padding: 1em 2em;
  box-shadow: 3px 2px 6px 0px rgba(0, 0, 0, 0.35);
  text-align: center;
  min-width: 300px;
  color: #fff;
`
