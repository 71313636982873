import ThemeProvider from "./theme"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import { QueryClientProvider, QueryClient } from "react-query"
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react"
// import { ReactQueryDevtools } from "react-query/devtools"
import Header from "components/Header"
import Footer from "components/Footer"
import { LandingRoute, PrimaryRoutes } from "dataset/routes"
import Content from "components/Content"
import Sidebar from "components/Sidebar"
import Navbar from "components/Navbar"

const queryClient = new QueryClient()

const instance = createInstance({
  urlBase: "https://c4ads.matomo.cloud",
  siteId: 14,
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `15
  },
  linkTracking: true, // optional, default value: true
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: "POST"
  }
})

export default function App() {
  return (
    <MatomoProvider value={instance}>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Header />
            <Navbar />
            <Content id="stickySelectorFocus">
              <Sidebar />
              <Switch>
                <Route
                  exact
                  path={LandingRoute.path}
                  component={LandingRoute.component}
                />
                {PrimaryRoutes.map(
                  ({ path, component, hasSubRoutes }, index) => (
                    <Route
                      exact={!hasSubRoutes}
                      key={index}
                      path={path}
                      component={component}
                    ></Route>
                  )
                )}
              </Switch>
            </Content>
            <Footer />
          </BrowserRouter>
          {/* <ReactQueryDevtools /> */}
        </QueryClientProvider>
      </ThemeProvider>
    </MatomoProvider>
  )
}
