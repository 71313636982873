import { useRef, useEffect } from "react"
import * as am4core from "@amcharts/amcharts4/core"
import * as am4maps from "@amcharts/amcharts4/maps"
import am4geodata_continentsLow from "@amcharts/amcharts4-geodata/continentsLow"
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow"
import am4themes_animated from "@amcharts/amcharts4/themes/animated"
import styled from "styled-components"
import { useMedia } from "react-media"
import { device } from "theme/device"
import { useStore } from "state/store"
import { removeAMChartMarker } from "utils/removeAMChartMarker"

import CountryNameMatches from "dataset/country_name_matches.json"

am4core.useTheme(am4themes_animated)

const colorGlobeSea = "#ffffff"
const colorGlobeCountry = "#DADFD9"
const colorGlobeCountryEdge = "#aaaea9"
const colorGlobeCountryHover = "#7537b5"
const colorGlobeCountryActive = "#f79811"

export default function Globe({ data = [] }) {
  const isGlobe = useStore(state => state.isGlobe)
  const setProfileGlobe = useStore(state => state.setProfileGlobe)
  const isMobileScreen = useMedia({ query: device.mobileL })

  const currentPolygon = useRef(null)

  const setOpenProfileDialog = useStore(state => state.setOpenProfileDialog)
  const setSelectedCountryName = useStore(state => state.setSelectedCountryName)

  useEffect(() => {
    /**
     * Create a globe
     */
    let chart = am4core.create("globediv", am4maps.MapChart)
    setProfileGlobe(chart)

    try {
      chart.geodata = am4geodata_worldLow
    } catch (e) {
      chart.raiseCriticalError(
        new Error(
          'Map geodata could not be loaded. Please download the latest <a href="https://www.amcharts.com/download/download-v4/">amcharts geodata</a> and extract its contents into the same directory as your amCharts files.'
        )
      )
    }

    // Set projection
    chart.projection = new am4maps.projections.Orthographic()
    chart.padding(20, 20, 20, 20)

    //Sea
    chart.backgroundSeries.mapPolygons.template.polygon.fill =
      am4core.color(colorGlobeSea)
    chart.backgroundSeries.mapPolygons.template.polygon.fillOpacity = 1
    chart.deltaLongitude = 20
    chart.deltaLatitude = -20

    // limits vertical rotation
    chart.adapter.add("deltaLatitude", function (delatLatitude) {
      return am4core.math.fitToRange(delatLatitude, -90, 90)
    })

    // Create map polygon series for shadow
    let shadowPolygonSeries = chart.series.push(new am4maps.MapPolygonSeries())
    shadowPolygonSeries.geodata = am4geodata_continentsLow

    try {
      shadowPolygonSeries.geodata = am4geodata_continentsLow
    } catch (e) {
      shadowPolygonSeries.raiseCriticalError(
        new Error(
          'Map geodata could not be loaded. Please download the latest <a href="https://www.amcharts.com/download/download-v4/">amcharts geodata</a> and extract its contents into the same directory as your amCharts files.'
        )
      )
    }

    shadowPolygonSeries.useGeodata = true
    shadowPolygonSeries.dx = 2
    shadowPolygonSeries.dy = 2
    shadowPolygonSeries.mapPolygons.template.fill = am4core.color("#000")
    shadowPolygonSeries.mapPolygons.template.fillOpacity = 0.3
    shadowPolygonSeries.mapPolygons.template.strokeOpacity = 0
    shadowPolygonSeries.fillOpacity = 0.1
    shadowPolygonSeries.fill = am4core.color("#000")

    // Create map polygon series
    let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries())
    // Set heatmap values for each state
    polygonSeries.data = data
    // Make map load polygon data (state shapes and names) from GeoJSON
    polygonSeries.useGeodata = true
    // polygonSeries.calculateVisualCenter = false;

    // Country tooltip
    let countryTooltip = polygonSeries.tooltip
    countryTooltip.background.fill = am4core.color(colorGlobeCountryHover)
    countryTooltip.background.fillOpacity = 1
    countryTooltip.background.strokeOpacity = 1
    countryTooltip.background.strokeWidth = 1
    countryTooltip.background.cornerRadius = 2
    countryTooltip.autoTextColor = false
    countryTooltip.getFillFromObject = false
    countryTooltip.label.fill = am4core.color("#fff")
    countryTooltip.label.interactionsEnabled = true
    countryTooltip.keepTargetHover = false

    // Country Polygon
    let polygonTemplate = polygonSeries.mapPolygons.template
    polygonTemplate.fill = am4core.color(colorGlobeCountry)
    polygonTemplate.fillOpacity = 1
    polygonTemplate.stroke = am4core.color(colorGlobeCountryEdge)
    polygonTemplate.strokeWidth = 0.5
    polygonTemplate.textAlign = "center"
    polygonTemplate.tooltipText = `Number of Seizures Linked to {name}: {realvalue}`
    polygonTemplate.propertyFields.id = "id"

    function handleCountrySelect() {
      const countryCode = currentPolygon.current.dataItem.dataContext.id
      const countryName = CountryNameMatches.find(
        country => country.code === countryCode
      )?.c4ads

      setSelectedCountryName(countryName)
      setOpenProfileDialog(true)
    }

    function getZoomLevel(mapPolygon) {
      let w = mapPolygon.polygon.bbox.width
      let h = mapPolygon.polygon.bbox.width
      // change 2 to smaller walue for a more close zoom
      return Math.min(chart.seriesWidth / (w * 2), chart.seriesHeight / (h * 2))
    }

    function handleCountryHit(event) {
      if (!isGlobe) {
        return
      }

      if (event.target === currentPolygon.current) {
        return
      }

      //Set active
      if (currentPolygon.current !== null) {
        currentPolygon.current.isActive = false
      }

      event.target.isActive = true

      currentPolygon.current = event.target

      chart.goHome()

      let animRot = chart.animate(
        [
          {
            property: "deltaLongitude",
            to: -event.target.visualLongitude
          },
          {
            property: "deltaLatitude",
            to: -event.target.visualLatitude
          }
        ],
        1000,
        am4core.ease.linear
      )

      animRot.events.on("animationended", function () {
        let animZoom = chart.zoomToMapObject(
          event.target,
          getZoomLevel(event.target),
          true,
          800
        )
        animZoom.events.on("animationended", handleCountrySelect)
      })
    }

    polygonTemplate.events.on("over", function (event) {
      if (event.target.dummyData) {
        event.target.dummyData.isHover = true
      }
    })
    polygonTemplate.events.on("out", function (event) {
      if (event.target.dummyData) {
        event.target.dummyData.isHover = false
      }
    })
    polygonTemplate.events.on("hit", handleCountryHit)

    //Set min/max fill color for each area
    polygonSeries.heatRules.push({
      target: polygonTemplate,
      property: "fill",
      dataField: "value",
      min: am4core.color("#DADFD9"),
      max: am4core.color("#17514E")
    })

    // Create hover state and set alternative fill color
    let polygonHoverState =
      polygonSeries.mapPolygons.template.states.create("hover")
    polygonHoverState.properties.fill = am4core.color(colorGlobeCountryHover)

    // Create active state and set alternative fill color
    let polygonActiveState =
      polygonSeries.mapPolygons.template.states.create("active")
    polygonActiveState.properties.fill = am4core.color(colorGlobeCountryActive)

    // Set up heat legend
    let heatLegend = chart.createChild(am4maps.HeatLegend)
    heatLegend.series = polygonSeries
    heatLegend.align = "left"
    heatLegend.valign = "bottom"
    heatLegend.width = am4core.percent(isMobileScreen ? 90 : 25)
    heatLegend.minValue = 0
    heatLegend.maxValue = data[0]?.realvalue
    heatLegend.visible = false

    // Set up custom heat map legend labels using axis ranges
    var minRange = heatLegend.valueAxis.axisRanges.create()
    minRange.value = heatLegend.minValue
    // minRange.label.text = "Little"
    var maxRange = heatLegend.valueAxis.axisRanges.create()
    maxRange.value = heatLegend.maxValue
    // maxRange.label.text = "A lot!"

    //Switch between map and globe
    if (isGlobe) {
      //Map grid
      let graticuleSeries = chart.series.push(new am4maps.GraticuleSeries())
      graticuleSeries.mapLines.template.stroke = am4core.color("#000")
      graticuleSeries.fitExtent = false

      //Projection
      chart.projection = new am4maps.projections.Orthographic() // Globe
      chart.backgroundSeries.show()
      shadowPolygonSeries.show()
      chart.panBehavior = "rotateLongLat"
    } else {
      chart.projection = new am4maps.projections.Projection() // Map
      chart.minZoomLevel = 1
      chart.maxZoomLevel = 1
      chart.seriesContainer.draggable = false
      chart.seriesContainer.resizable = false
      chart.chartContainer.wheelable = false
      chart.homeGeoPoint = {
        latitude: 22.907663039030606,
        longitude: 13.323338462923601
      }

      chart.backgroundSeries.hide()
      shadowPolygonSeries.hide()
      chart.panBehavior = "move"
    }

    //Remove marker
    removeAMChartMarker()

    return () => {
      if (chart) {
        chart.dispose()
      }
      if (document.getElementById("globediv")) {
        document.getElementById("globediv").innerHTML = ""
      }
    }
    // eslint-disable-next-line
  }, [isGlobe, data])

  return <GlobeHolder id="globediv"></GlobeHolder>
}

const GlobeHolder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
