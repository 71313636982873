import { useEffect, useMemo } from "react"

import styled from "styled-components"
import { useStore } from "state/store"
import { useQuery } from "react-query"
import { Switch, Route, useHistory } from "react-router-dom"
import { useMatomo } from "@datapunt/matomo-tracker-react"

import { getCountryMap } from "api"
import {
  convert2Region,
  convert2Method,
  convert2Specie
} from "utils/convertDataFormat"
import { device } from "theme/device"

import { SideContainer } from "components/Containers"
import Loader from "components/Loader"
import Globe from "components/Globe"
import OutlineButton from "components/OutlineButton"

import mapData from "dataset/states-list-with-continents.json"
import CountryNameMatches from "dataset/country_name_matches.json"

import ArrowDownIcon from "assets/images/icon-arrow-down.svg"
import { AboutRoute } from "dataset/routes"

export default function Home() {
  const { trackPageView } = useMatomo()
  const history = useHistory()

  const setSideBarVisible = useStore(state => state.setSideBarVisible)
  const setIsGlobe = useStore(state => state.setIsGlobe)
  const selectedSpecie = useStore(state => state.selectedSpecie)
  const selectedMethod = useStore(state => state.selectedMethod)
  const selectedRegion = useStore(state => state.selectedRegion)
  const selectedDuringFrom = useStore(state => state.selectedDuringFrom)
  const selectedDuringTo = useStore(state => state.selectedDuringTo)

  const specieData = convert2Specie(selectedSpecie)
  const methodData = convert2Method(selectedMethod)
  const regionData = convert2Region(selectedRegion)

  const {
    data: speciesMap,
    error: speciesMapError,
    isError: speciesMapIsError,
    isLoading: speciesMapIsLoading
  } = useQuery(
    [
      "getCountryMap",
      specieData,
      methodData,
      selectedDuringFrom,
      selectedDuringTo,
      regionData
    ],
    () =>
      getCountryMap({
        analyze: specieData,
        transport_type: methodData,
        date_from: selectedDuringFrom,
        date_to: selectedDuringTo,
        region: regionData
      }),
    { keepPreviousData: true }
  )

  const globeData = useMemo(() => {
    const newData = []
    if (speciesMap) {
      mapData.forEach(feature => {
        const idx = speciesMap.findIndex(c => c.cads_name === feature.state)
        if (idx >= 0) {
          newData.push(speciesMap[idx])
        } else {
          const countryCode = CountryNameMatches.find(
            country => country.c4ads === feature.state
          )?.code
          const countryName = CountryNameMatches.find(
            country => country.c4ads === feature.state
          )?.amchart4

          if (countryCode && countryName) {
            const idxx = newData.findIndex(a => a.id === countryCode)
            if (idxx === -1) {
              newData.push({
                id: countryCode,
                name: countryName,
                cads_name: feature.state,
                value: 0,
                realvalue: 0
              })
            }
          }
        }
      })
    }
    return newData
  }, [speciesMap])

  useEffect(() => {
    trackPageView({
      documentTitle: window.location.pathname + window.location.search
    })
    setSideBarVisible(true)
    setIsGlobe(false)
    // eslint-disable-next-line
  }, [])

  return (
    <Holder>
      <Switch>
        <Route exact path={"/home"}>
          <ChartHolder>
            {speciesMapIsLoading ? (
              <Loader />
            ) : speciesMapIsError ? (
              speciesMapError
            ) : (
              <>
                <Title>Countries in Wildlife Seizure Trafficking Routes</Title>
                <Globe data={globeData} />
                <LearnMoreButton
                  icon={ArrowDownIcon}
                  onClick={() => {
                    history.push(AboutRoute.path)
                  }}
                >
                  Learn More
                </LearnMoreButton>
              </>
            )}
          </ChartHolder>
        </Route>
        <Route exact path={AboutRoute.path} component={AboutRoute.component} />
      </Switch>
    </Holder>
  )
}

const Holder = styled(SideContainer)`
  padding: 0;
  min-height: 350px;
`

const ChartHolder = styled.div`
  height: 80vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${device.pad} {
    height: 40vh;
  }
`
const LearnMoreButton = styled(OutlineButton)`
  width: 15em;
  position: absolute;
  bottom: 4em;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
`
const Title = styled.p`
  position: absolute;
  top: 0;
  z-index: 1;
  font-size: 2em;
  font-weight: bold;
  line-height: 140%;
  color: #144a68;
  padding: 0.5em;
  margin: 0;
  text-align: center;
  @media ${device.pad} {
    font-size: 1em;
    width: 70%;
  }
`
