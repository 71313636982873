import styled from "styled-components"
import { device } from "theme/device"

export const SLTitle = styled.p`
  font-size: 2em;
  font-weight: bold;
  line-height: 140%;
  color: #144a68;
  @media ${device.mobileL} {
    font-size: 1.2em;
  }
`

export const SLSubTitle = styled.p`
  font-size: 1.5em;
  font-weight: bold;
  line-height: 140%;
  color: #144a68;
  text-align: justify;
  @media ${device.mobileL} {
    font-size: 1.1em;
  }
`

export const SLDesc = styled.p`
  font-size: 1em;
  font-weight: 400;
  line-height: 140%;
  color: #144a68;
  text-align: justify;
  @media ${device.mobileL} {
    font-size: 0.9em;
  }
`

export const SLTips = styled.div`
  font-size: 1em;
  font-weight: 400;
  line-height: 140%;
  color: #144a68;
  padding: 0;
  margin-bottom: 3em;
  div {
    margin-bottom: 1em;
    margin-left: 1em;
    position: relative;
    overflow-x: visible;
    &:after {
      content: "";
      position: absolute;
      left: -1em;
      top: 0.6em;
      width: 0.3em;
      height: 0.3em;
      background-color: #144a68;
      border-radius: 50%;
    }
  }
  @media ${device.mobileL} {
    font-size: 0.9em;
  }
`
export const SLReactText = styled.div`
  font-size: 1em;
  font-weight: 400;
  line-height: 140%;
  color: #144a68;
  margin: 1.5em 0;
  padding: 1em;
  background-color: #f3f9fd;
  border: 1px solid #144a68;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.08);
  &:first-letter {
    text-transform: uppercase;
  }
  @media ${device.mobileL} {
    font-size: 0.9em;
  }
`

export const ChartDesc = styled.div`
  color: #144a68;
  padding: 1em;
  background-color: #f3f9fd;
  word-break: break-word;
`

export const FAQ = styled.div`
  background-color: #eff8fe;
  padding: 1em;
  margin-bottom: 0.5em;
  h2 {
    font-size: 1em;
    font-weight: bold;
    line-height: 140%;
    color: #000000;
    text-align: left;
    padding: 0;
    margin: 0;
    margin-bottom: 0.5em;
  }
  p {
    font-size: 0.95em;
    line-height: 140%;
    color: #000000;
    text-align: left;
    padding: 0;
    margin: 0;
  }
`
