import { useState, useEffect } from "react"
import { forEach } from "lodash"
import styled from "styled-components"
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import am4themes_animated from "@amcharts/amcharts4/themes/animated"
import { removeAMChartMarker } from "utils/removeAMChartMarker"
import { useMedia } from "react-media"
import { device } from "theme/device"
import { useStore } from "state/store"
import { convert2Specie } from "utils/convertDataFormat"

am4core.useTheme(am4themes_animated)

export default function TransportTypePieChart({ data = [] }) {
  const isPadScreen = useMedia({ query: device.pad })

  const selectedSpecie = useStore(state => state.selectedSpecie)

  const [weightChartVisible, setWeightChartVisible] = useState(true)

  useEffect(() => {
    CreateCountChart(data, isPadScreen)
    CreateWeightChart(data, isPadScreen)
    removeAMChartMarker()
  }, [data, isPadScreen])

  useEffect(() => {
    const specieData = convert2Specie(selectedSpecie)
    if (
      specieData.includes("tiger") ||
      specieData.includes("leopard") ||
      specieData.includes("lion")
    ) {
      setWeightChartVisible(false)
    } else {
      setWeightChartVisible(true)
    }
  }, [selectedSpecie])

  return (
    <Holder>
      <ChartHolder
        id="transport_type_count_em_chart"
        visible={true}
      ></ChartHolder>
      <ChartHolder
        id="transport_type_weight_em_chart"
        visible={weightChartVisible}
      ></ChartHolder>
    </Holder>
  )
}

const Holder = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
`

const ChartHolder = styled.div`
  min-width: 300px;
  flex: 1;
  height: 300px;
  display: ${props => (props.visible ? "block" : "none")};
  @media ${device.mobileL} {
    height: 200px;
  }
`

function CreateCountChart(data, isPadScreen) {
  // Create chart instance
  let chart = am4core.create(
    "transport_type_count_em_chart",
    am4charts.PieChart
  )

  // Add data
  chart.data = data

  chart.radius = am4core.percent(75)
  chart.innerRadius = am4core.percent(43)
  chart.startAngle = 180
  chart.endAngle = 360

  // Add and configure Series
  let pieSeries = chart.series.push(new am4charts.PieSeries())
  pieSeries.dataFields.value = "count"
  pieSeries.dataFields.category = "label"

  pieSeries.slices.template.stroke = am4core.color("#fff")
  pieSeries.slices.template.strokeWidth = 2
  pieSeries.slices.template.strokeOpacity = 0

  //Disable Hover and Click for Slice
  // pieSeries.slices.template.states.getKey("hover").properties.scale = 1
  pieSeries.slices.template.states.getKey("active").properties.shiftRadius = 0

  //Tooltip
  pieSeries.tooltip.autoTextColor = false
  pieSeries.tooltip.label.fill = am4core.color("#122945")
  pieSeries.tooltip.label.fontSize = isPadScreen ? 12 : 14
  pieSeries.tooltip.label.fontWeight = 400

  pieSeries.tooltip.getFillFromObject = false
  pieSeries.tooltip.getStrokeFromObject = true
  pieSeries.tooltip.background.fill = am4core.color("#ffffff")

  pieSeries.slices.template.tooltipText =
    "Category: {category} \n Number of Seizures: {count} \n Percentage of Total: {value.percent.formatNumber('#.#')}%"

  // Set color
  let colors = []
  forEach(data, item => {
    colors.push(am4core.color(item.color))
  })

  pieSeries.colors.list = colors

  // This creates initial animation
  pieSeries.hiddenState.properties.opacity = 0
  pieSeries.hiddenState.properties.endAngle = 90
  pieSeries.hiddenState.properties.startAngle = 90

  pieSeries.alignLabels = true
  pieSeries.labels.template.padding(0, 0, 0, 0)
  pieSeries.labels.template.maxWidth = isPadScreen ? 40 : 70
  pieSeries.labels.template.wrap = true
  pieSeries.labels.template.fontSize = isPadScreen ? 10 : 14
  pieSeries.labels.template.disabled = true

  //Add legend
  // chart.legend = new am4charts.Legend();
  // chart.legend.position = "bottom";
  // let markerTemplate = chart.legend.markers.template;
  // markerTemplate.width = 15;
  // markerTemplate.height = 15;

  //Center label
  let label = pieSeries.createChild(am4core.Label)
  label.text = "Number of Seizures"
  label.horizontalCenter = "middle"
  label.verticalCenter = "bottom"
  label.fontSize = isPadScreen ? 10 : 14
  label.fontWeight = 600
  label.maxWidth = isPadScreen ? 70 : 120
  label.wrap = true
  label.align = "center"
  label.contentAlign = "center"
  label.textAlign = "middle"
  label.fill = am4core.color("#144A68")
}

function CreateWeightChart(data, isPadScreen) {
  // Create chart instance
  let chart = am4core.create(
    "transport_type_weight_em_chart",
    am4charts.PieChart
  )

  // Add data
  chart.data = data

  chart.radius = am4core.percent(75)
  chart.innerRadius = am4core.percent(43)
  chart.startAngle = 180
  chart.endAngle = 360

  // Add and configure Series
  let pieSeries = chart.series.push(new am4charts.PieSeries())
  pieSeries.dataFields.value = "weight"
  pieSeries.dataFields.category = "label"

  pieSeries.slices.template.stroke = am4core.color("#fff")
  pieSeries.slices.template.strokeWidth = 2
  pieSeries.slices.template.strokeOpacity = 0

  //Disable Hover and Click for Slice
  // pieSeries.slices.template.states.getKey("hover").properties.scale = 1
  pieSeries.slices.template.states.getKey("active").properties.shiftRadius = 0

  //Tooltip
  pieSeries.tooltip.autoTextColor = false
  pieSeries.tooltip.label.fill = am4core.color("#122945")
  pieSeries.tooltip.label.fontSize = isPadScreen ? 12 : 14
  pieSeries.tooltip.label.fontWeight = 400

  pieSeries.tooltip.getFillFromObject = false
  pieSeries.tooltip.getStrokeFromObject = true
  pieSeries.tooltip.background.fill = am4core.color("#ffffff")

  pieSeries.slices.template.tooltipText =
    "Category: {category} \n Weight of Seizures: {value}kg \n Percentage of Total: {value.percent.formatNumber('#.#')}%"

  // Set color
  let colors = []
  forEach(data, item => {
    colors.push(am4core.color(item.color))
  })

  pieSeries.colors.list = colors

  // This creates initial animation
  pieSeries.hiddenState.properties.opacity = 0
  pieSeries.hiddenState.properties.endAngle = 90
  pieSeries.hiddenState.properties.startAngle = 90

  pieSeries.alignLabels = true
  pieSeries.labels.template.padding(0, 0, 0, 0)
  pieSeries.labels.template.maxWidth = 70
  pieSeries.labels.template.wrap = true
  pieSeries.labels.template.fontSize = isPadScreen ? 10 : 14
  pieSeries.labels.template.disabled = true

  //Add legend
  // chart.legend = new am4charts.Legend();
  // chart.legend.position = "bottom";
  // let markerTemplate = chart.legend.markers.template;
  // markerTemplate.width = 15;
  // markerTemplate.height = 15;

  //Center label
  let label = pieSeries.createChild(am4core.Label)
  label.text = "Total Weight of Wildlife Seized (kg)"
  label.horizontalCenter = "middle"
  label.verticalCenter = "bottom"
  label.fontSize = isPadScreen ? 10 : 14
  label.fontWeight = 600
  label.maxWidth = isPadScreen ? 95 : 130
  label.wrap = true
  label.align = "center"
  label.contentAlign = "center"
  label.textAlign = "middle"
  label.fill = am4core.color("#144A68")
}
