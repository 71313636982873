import Home from "pages/Home"
import OverTime from "pages/OverTime"
import TransportType from "pages/TransportType"
import Location from "pages/Location"
import CountryProfile from "pages/CountryProfile"
import Landing from "pages/Landing"
import About from "pages/Home/About"

export const LandingRoute = {
  label: "Landing",
  path: "/",
  component: Landing
}

export const AboutRoute = {
  label: "About",
  path: "/home/about",
  component: About
}

export const PrimaryRoutes = [
  { label: "Home", path: "/home", component: Home, hasSubRoutes: true },
  {
    label: "Over Time",
    path: "/over_time",
    component: OverTime,
    hasSubRoutes: false
  },
  {
    label: "Transport Type",
    path: "/transport_type",
    component: TransportType,
    hasSubRoutes: false
  },
  {
    label: "Location",
    path: "/location",
    component: Location,
    hasSubRoutes: false
  },
  {
    label: "Country Profile",
    path: "/country_profile",
    component: CountryProfile,
    hasSubRoutes: false
  }
]
