import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle
} from "styled-components"
import Div100vh from "react-div-100vh"
import { isMobile } from "react-device-detect"
import { normalize } from "polished"

import theme from "./theme"

//Load primereact styles
import "./tailwind-light-theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"

//Load bootstrap styles
import "bootstrap/dist/css/bootstrap.min.css"

const GlobalStyle = createGlobalStyle`
  ${normalize()}

  body {
    color: ${theme.colors.primary};
    font-Size: 16px;
    font-family: "centry_gothic";
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    touch-action: pan-x pan-y;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }

  *::-webkit-scrollbar {
    width: 15px;
    height: 15px;
  }

  *::-webkit-scrollbar-track {
    background-color: rgb(235, 235, 235);
  }

  *::-webkit-scrollbar-thumb {
    background-color: #043D5D;
    border-radius: 10px;
    border: 5px solid #f1f1f1;
  }

  div.chord-tooltip {
    position: absolute;
    text-align: center;
    width: 180px;
    padding: 2px;
    font: 12px sans-serif;
    background: #ffffff80;
    border: 1px solid #888;
    border-radius: 4px;
    pointer-events: none;
  }

  .__react_component_tooltip{
    background: #061830 !important;
    max-width: 300px !important;
  }
`

const MobileContainer = styled(Div100vh)`
  width: 100vw;
`

const DesktopContainer = styled.div`
  width: calc(100vw - 6px);
  height: 100vh;
`

export default function ThemeProvider({ children }) {
  return (
    <StyledComponentsThemeProvider theme={theme}>
      <GlobalStyle />
      {isMobile ? (
        <MobileContainer> {children} </MobileContainer>
      ) : (
        <DesktopContainer> {children} </DesktopContainer>
      )}
    </StyledComponentsThemeProvider>
  )
}
