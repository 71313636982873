const START_YEAR = 2013
const END_YEAR = new Date().getFullYear()

let StartDateItems = []
let EndDateItems = []
for (let i = 0; i <= END_YEAR - START_YEAR; i++) {
  const year = START_YEAR + i
  StartDateItems.push({
    label: year.toString(),
    value: `01/01/${year}`
  })
  EndDateItems.push({
    label: year.toString(),
    value: `12/31/${year}`
  })
}

export { StartDateItems, EndDateItems }
