import React, { useRef } from "react"
import styled from "styled-components"
import { useMatomo } from "@datapunt/matomo-tracker-react"
import { IoMdDownload } from "react-icons/io"
import { downloadImage } from "utils"
import {
  SpecyLegendData,
  SpecyWeightLegendData,
  TransportTypeLegendData
} from "dataset/legend"
import { device } from "theme/device"

export default function Card({
  title,
  desc,
  className,
  children,
  showSpecies = true,
  showDownload = false,
  legendType = "specy",
  weightSeized = false
}) {
  const { trackEvent } = useMatomo()

  const ref = useRef(null)
  const handleDownload = () => {
    if (ref.current) {
      downloadImage(ref.current)
      trackEvent({ category: "Downloads", action: "Chart Report Download" })
    }
  }
  return (
    <Container ref={ref} className={className}>
      <Header>
        <HeaderLeft>
          <Title>{title}</Title>
          <Desc>{desc}</Desc>
          {showSpecies && (
            <LegendGroup>
              {legendType === "transport"
                ? TransportTypeLegendData.map((item, index) => {
                    return <Legend key={index} {...item} />
                  })
                : weightSeized
                ? SpecyWeightLegendData.map((item, index) => {
                    return <Legend key={index} {...item} />
                  })
                : SpecyLegendData.map((item, index) => {
                    return <Legend key={index} {...item} />
                  })}
            </LegendGroup>
          )}
        </HeaderLeft>
        {showDownload && (
          <DownloadButton
            onClick={handleDownload}
            className={!showSpecies ? "ml-auto" : ""}
          >
            <IoMdDownload color="black" size={24} />
          </DownloadButton>
        )}
      </Header>
      <Content>{children}</Content>
    </Container>
  )
}

const Container = styled.div`
  border: 1px solid #cde2ef;
  width: 100%;
`

const Header = styled.div`
  background: #f3f9fd;
  padding: 16px 24px;
  display: flex;
  gap: 0.5em;
`

const HeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`

const Title = styled.div`
  color: #144a68;
  font-size: 1.2em;
  font-weight: bold;
  word-break: break-word;
  @media ${device.laptop} {
    font-size: 0.9em;
    letter-spacing: 0.01em;
  }
  @media ${device.mobileL} {
    font-size: 0.9em;
  }
`

const Desc = styled.div`
  color: #144a68;
  font-size: 0.9em;
  max-width: 800px;
  @media ${device.mobileL} {
    font-size: 0.7em;
  }
`

const LegendGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`

const LegendContainer = styled.div`
  display: flex;
  cursor: pointer;
  transition: all 300ms ease;
  margin-right: 20px;

  &:hover {
    opacity: 0.7;
  }
`
const LegendBullet = styled.div`
  background: ${props => props.color};
  margin: auto 4px auto 0;
  width: 8px;
  min-width: 8px;
  height: 8px;
  min-height: 8px;
  border-radius: 50%;
`

const LegendText = styled.div`
  color: #122945;
  font-size: 12px;
  margin: auto 0;
`

const Content = styled.div`
  background: white;
  min-height: 300px;
`

const DownloadButton = styled.div`
  width: 34px;
  height: 34px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  transition: all 300ms ease;
  &:hover {
    opacity: 0.7;
  }
`

export const Legend = ({ label, color, className }) => {
  return (
    <LegendContainer className={className}>
      <LegendBullet color={color} />
      <LegendText>{label}</LegendText>
    </LegendContainer>
  )
}
